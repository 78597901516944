class FetchActiveChallengesParams {
  constructor(
    public active: string,
    public sortDirection: 'ASC' | 'DESC',
    public limit: number,
    public nextToken: any,
    public startDate?: any,
    public filter?: any,
  ) {}
}

export default FetchActiveChallengesParams;
