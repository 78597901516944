import React, { useEffect, useState } from 'react';
import { Grid, createStyles, makeStyles } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { Group } from '@material-ui/icons';
import { useAuthContext } from '../../common/context/AuthContext';
import FetchListParams from '../../utils/FetchListParams';
import { fetchChallengesList } from '../../common/api/apiclient';
import { Challenge, ListChallengesQuery } from '../../API';
import { ChallengeBox } from '../../components/ChallengeBox/ChallengeBox';
import AddActivityButton from '../../components/activities/AddActivityButton';
import { LoadingBox } from '../../components/LoadingBox/LoadingBox';
import { GoGreenHeader } from '../../components/headers/GoGreenHeader';

const useStyles = makeStyles(() =>
  createStyles({
    subtitle: {
      padding: '15px 0 0 20px',
    },
    link: {
      color: '#7500C0',
      fontWeight: 'bold',
      '&:hover': {
        color: '#7500C0',
      },
    },
    challangeListBox: {
      position: 'relative',
      marginBottom: '75px',
      width: '100%',
    },
  }),
);

export function GoGreenHomePage() {
  const classes = useStyles();
  const [challengeList, setChallengeList] = useState<ListChallengesQuery['listChallenges']>();
  const [activeChallenge, setActiveChallenge] = useState<Challenge>();
  const { user } = useAuthContext();
  const tokenPayload = user?.getSignInUserSession()?.getIdToken().payload;
  const history = useHistory();

  const loadChallengeList = async () => {
    const filter = {
      id: { contains: '' },
    };
    const fetchParams = new FetchListParams(filter, 20, null);
    const loadedChallengeList = await fetchChallengesList(fetchParams);
    setChallengeList(loadedChallengeList);

    const activeChallanges = loadedChallengeList?.items.filter((challange) => {
      return challange!.active === 'true' && challange!.draft === 'false';
    });
    if (activeChallanges) {
      setActiveChallenge(activeChallanges[0] as Challenge);
    }
  };

  useEffect(() => {
    loadChallengeList();
  }, []);

  return (
    <>
      <GoGreenHeader icon={Group} title="Challenges" />
      <h5 className={classes.subtitle}>
        Hi, {tokenPayload?.given_name} {tokenPayload?.family_name}
      </h5>
      {}
      {challengeList && (
        <>
          <ChallengeBox
            isActive
            title={`${activeChallenge?.name as string} is here!`}
            onClick={() => history.push(`/gogreen/challenges/${activeChallenge?.id}/activityTypes`)}
          >
            {activeChallenge?.id && (
              <>
                Take part now, earn points and help the environment in your city.{' '}
                <Link to={`/gogreen/challenges/${activeChallenge?.id}/activityTypes`} className={classes.link}>
                  START NOW {'>>'}
                </Link>{' '}
                <h6>
                  {activeChallenge?.startDate} - {activeChallenge?.endDate}
                </h6>
              </>
            )}
          </ChallengeBox>
        </>
      )}
      <Grid container>
        <Grid item xs={12}>
          <h5 className={classes.subtitle}>Past Challenges</h5>
        </Grid>
        <Grid className={classes.challangeListBox}>
          {challengeList?.items ? (
            <>
              {challengeList?.items
                ?.slice(0)
                .reverse()
                .map(
                  (challenge) =>
                    challenge?.active === 'false' && (
                      <Grid item xs={12} key={challenge?.id}>
                        <ChallengeBox title={challenge.name}>
                          {challenge.startDate as string} - {challenge.endDate}
                        </ChallengeBox>
                      </Grid>
                    ),
                )}
            </>
          ) : (
            <LoadingBox />
          )}
          {activeChallenge?.id && <AddActivityButton challengeId={activeChallenge?.id} />}
        </Grid>
      </Grid>
    </>
  );
}
