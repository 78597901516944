import React, { useCallback, useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, createStyles, makeStyles, Theme, Button } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { Carousel } from 'react-responsive-carousel';
import { useAuthContext } from '../../common/context/AuthContext';
import {
  updateReserveProduct,
  getProduct,
  getUploadedImageUrl,
  createMyTransaction,
  getUserProfileByEmail,
  deleteProduct,
} from '../../common/api/apiclient';
import { UpdateProductInputInput, CreateTransactionInput, DeleteProductInputInput } from '../../API';

import InformationDialog from '../../components/dialogs/InformationDialog';
import { EcoswapHeader } from '../../components/headers/EcoswapHeader';
import { ProductInfo } from '../../components/products/ProductInfo';
import { ImageDialog } from '../../components/dialogs/ImageDialog';
import { DecisionDialog } from '../../components/dialogs/DecisionDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      paddingLeft: '2%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    title: {
      fontSize: '45px',
      fontWeight: 'bold',
      color: '#7500C0',
      marginLeft: '2%',
      marginBottom: '5px',
    },
    button: {
      fontWeight: 'bold',
      textAlign: 'center',
      width: '13em',
      height: '3.5em',
      backgroundColor: 'white',
      color: '#7500C0',
      borderRadius: '40px',
    },
    purpleButton: {
      border: '4px solid #7500C0',
      '&:hover': {
        backgroundColor: '#7500C0',
        color: 'white',
      },
    },
    greenButton: {
      border: '4px solid #34e218',
      color: '#34e218',
      '&:hover': {
        backgroundColor: '#34e218',
        color: 'white',
      },
    },
    flex: {
      display: 'flex',
    },
    textPage: {
      width: '50%',
      textAlign: 'left',
      paddingLeft: '2%',
      marginTop: '5px',
      order: 1,
      [theme.breakpoints.down('md')]: {
        order: 2,
        marginBottom: '50px',
        paddingBottom: '70px',
      },
    },
    imagePage: {
      width: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      order: 2,
      borderLeft: '5px solid #7500C0',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        order: 1,
        borderLeft: 'none',
      },
    },
    image: {
      WebkitBoxShadow: '5px 5px 15px 5px rgba(0,0,0,0.49);',
      boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.49);',
    },
    imgWrapper: {
      width: '35%',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        width: '75%',
      },
    },
    productImage: {
      maxWidth: '100%',
      objectFit: 'contain',
    },
    productImageDialog: {
      objectFit: 'contain',
      maxHeight: '75vh',
      width: '100%',
    },
    centerX: {
      display: 'flex',
      justifyContent: 'center',
      padding: '5px 10px',
      minWidth: '12vw',
    },
  }),
);

type Params = {
  id: string;
};

export const ProductPage = () => {
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams<Params>();
  const { user } = useAuthContext();
  const userMail = user?.getUsername();
  const tokenPayload = user?.getSignInUserSession()?.getIdToken()?.payload;

  const [product, setProduct] = useState<any>();
  const [selectedImage, setSelectedImage] = useState();

  const [openReserveDialog, setOpenReserveDialog] = useState(false);
  const [openUnreserveDialog, setOpenUnreserveDialog] = useState(false);
  const [openUnreserveDecisionDialog, setOpenUnreserveDecisionDialog] = useState(false);
  const [openSuccessBuyDialog, setOpenSuccessBuyDialog] = useState(false);
  const [openBalanceDialog, setOpenBalanceDialog] = useState(false);
  const [openDeleteDecisionDialog, setOpenDeleteDecisionDialog] = useState(false);
  const [openSuccessDeleteDialog, setOpenSuccessDeleteDialog] = useState(false);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleOpenBalanceDialogClose = () => {
    setOpenBalanceDialog(false);
  };
  const handleOpenSuccessBuyDialogClose = () => {
    setOpenSuccessBuyDialog(false);
    history.push('/ecoswap/category');
  };

  const toggleOpenDeleteDecisionDialog = () => {
    setOpenDeleteDecisionDialog(!openDeleteDecisionDialog);
  };

  const handleOpenSuccessDeleteDialogClose = () => {
    setOpenSuccessDeleteDialog(false);
    history.push('/ecoswap/category');
  };

  const handleOpenReserveDialog = () => {
    setOpenReserveDialog(false);
    window.open(
      `mailto:${product.email}?subject=I%20would%20like%20to%20buy%20your%20${product.title}&body=Greetings!%0AWe%20could%20meet%20to%20see%20the%20product.%0ALooking%20forwards%20to%20meeting%20you.%0A%0ANote%20for%20the%20buyer:%0AThe%20original%20owner%20must%20buy%20the%20product%20after%20successfully%20handing%20over%20the%20product.`,
    );
  };

  const toggleOpenUnreserveDecisionDialog = () => {
    setOpenUnreserveDecisionDialog(!openUnreserveDecisionDialog);
  };

  const handleOpenUnreserveDialog = () => {
    setOpenUnreserveDialog(false);
    window.open(
      `mailto:${product.email}?subject=I%20have%20unreserved%20your%20${product.title}&body=Greetings!%0AUnfortunately,%20I%20am%20no%20longer%20interested%20in%20your%20product.%0AHave%20a%20great%20day.`,
    );
  };

  const handleOpenErrorDialog = () => {
    setOpenErrorDialog(false);
  };

  const toggleOpenImageDialog = (image: any) => {
    return () => {
      setSelectedImage(image);
      setOpenImageDialog(!openImageDialog);
    };
  };

  const loadProduct = useCallback(async () => {
    const loadedProduct: any = await getProduct(id);
    const productImages = await Promise.all(
      loadedProduct?.imageKeys.map(async (imageKey) => ({
        imageUrl: await getUploadedImageUrl(imageKey),
      })),
    );
    // eslint-disable-next-line no-console
    console.log('loadedProduct.isInOffer ', loadedProduct.isInOffer);
    setProduct({ ...loadedProduct, images: productImages });
  }, [id]);

  useEffect(() => {
    loadProduct();
  }, [loadProduct]);

  async function reserveProduct() {
    const buyerProfile = await getUserProfileByEmail(tokenPayload?.email);
    if (product.price > buyerProfile.balance) {
      setOpenBalanceDialog(true);
      return;
    }

    const productToUpdate: UpdateProductInputInput = {
      id: product.id,
      reservedFor: tokenPayload?.email,
    };

    try {
      await updateReserveProduct(productToUpdate);
    } catch (e) {
      setErrorMessage('The reservation was not succesfull. Please try again.');
      setOpenErrorDialog(true);
    }
    loadProduct();
    setOpenReserveDialog(true);
  }

  async function unreserveProduct() {
    setOpenUnreserveDecisionDialog(false);
    const productToUnreserve: UpdateProductInputInput = {
      id: product.id,
      reservedFor: null,
    };

    try {
      await updateReserveProduct(productToUnreserve);
      setOpenUnreserveDialog(true);
    } catch (e) {
      setErrorMessage('The cancellation of the reservation was not succesfull. Please try again.');
      setOpenErrorDialog(true);
    }
    loadProduct();
  }

  async function deleteUserProduct() {
    const productToDelete: DeleteProductInputInput = {
      id,
    };
    try {
      await deleteProduct(productToDelete);
      setOpenSuccessDeleteDialog(true);
    } catch (e) {
      setErrorMessage('The deletion of product was not succesfull. Please try again.');
      setOpenErrorDialog(true);
    }
  }

  async function purchaseProduct() {
    const buyerProfile = await getUserProfileByEmail(product.reservedFor);
    if (product.price > buyerProfile.balance) {
      setOpenBalanceDialog(true);
      return;
    }

    const transaction: CreateTransactionInput = {
      id: uuidv4(),
      sentFromEmail: product.reservedFor,
      sentToEmail: product.email,
      howMuch: product.price,
      whatFor: product.id,
    };

    try {
      createMyTransaction(transaction);
      setOpenSuccessBuyDialog(true);
    } catch (e) {
      setErrorMessage('The purchase of the product was unsuccessful. Please try again.');
      setOpenErrorDialog(true);
    }
  }

  return (
    <>
      <div className={classes.header}>
        <div>
          <EcoswapHeader />
        </div>
        <Button
          type="button"
          variant="contained"
          className={`${classes.button} ${classes.purpleButton}`}
          style={{ margin: '1.5em' }}
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </div>

      {product && <p className={classes.title}>{product.title}</p>}
      <Grid container>
        <Grid item className={classes.textPage} lg={5} md={12} xs={12}>
          {product && (
            <>
              <ProductInfo product={product} />
              <Grid container>
                {!product.sold && product?.reservedFor && product.reservedFor === tokenPayload?.email && (
                  <Grid item className={classes.centerX} lg={2} md={3} sm={4} xs={6}>
                    <Button
                      className={`${classes.button} ${classes.purpleButton}`}
                      variant="contained"
                      type="submit"
                      onClick={toggleOpenUnreserveDecisionDialog}
                    >
                      Cancel reservation
                    </Button>
                  </Grid>
                )}
                {!product.sold &&
                  product?.reservedFor &&
                  product?.reservedFor !== tokenPayload?.email &&
                  product.owner !== userMail && (
                    <>
                      <h5 style={{ fontSize: '25px', fontWeight: 'bold' }}>
                        <b>Product is reserved</b>
                      </h5>
                    </>
                  )}
                {!product.sold && !product.reservedFor && product.owner !== userMail && (
                  <Grid item className={classes.centerX} lg={2} md={3} sm={4} xs={6}>
                    <Button
                      className={`${classes.button} ${classes.purpleButton}`}
                      variant="contained"
                      type="submit"
                      onClick={reserveProduct}
                    >
                      Reserve product
                    </Button>
                  </Grid>
                )}
                {!product.sold && product.owner === userMail && (
                  <>
                    <Grid item className={classes.centerX} lg={2} md={3} sm={4} xs={6}>
                      <Button
                        className={`${classes.button} ${classes.purpleButton}`}
                        variant="contained"
                        type="submit"
                        onClick={toggleOpenDeleteDecisionDialog}
                      >
                        Delete product
                      </Button>
                    </Grid>
                    <Grid item className={classes.centerX} lg={2} md={3} sm={4} xs={6}>
                      <Button
                        className={`${classes.button} ${classes.greenButton}`}
                        variant="contained"
                        type="submit"
                        onClick={() => history.push(`/ecoswap/products/edit/${id}`)}
                      >
                        Modify product
                      </Button>
                    </Grid>
                    {product.reservedFor && (
                      <Grid item className={classes.centerX} lg={2} md={3} sm={4} xs={6}>
                        <Button
                          className={`${classes.button} ${classes.purpleButton}`}
                          variant="contained"
                          type="submit"
                          onClick={purchaseProduct}
                        >
                          Confirm purchase
                        </Button>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </>
          )}
        </Grid>
        <Grid item className={classes.imagePage} lg={7} md={12} xs={12}>
          <Carousel emulateTouch swipeable showThumbs={false}>
            {product?.images.map(({ imageUrl }) => (
              <div className={classes.imgWrapper} key={imageUrl} onClick={toggleOpenImageDialog(imageUrl)} aria-hidden>
                <img src={imageUrl} alt="product" className={classes.productImage} />
              </div>
            ))}
          </Carousel>
        </Grid>
      </Grid>

      <ImageDialog open={openImageDialog} onClose={() => setOpenImageDialog(!openImageDialog)}>
        <img src={selectedImage} alt="product" className={classes.productImageDialog} />
      </ImageDialog>
      <InformationDialog open={openReserveDialog} onClose={handleOpenReserveDialog} title="Success">
        You succesfully reserved this product.
      </InformationDialog>
      <InformationDialog open={openUnreserveDialog} onClose={handleOpenUnreserveDialog} title="Success">
        You succesfully cancel your reservation on this product.
      </InformationDialog>
      <InformationDialog open={openSuccessBuyDialog} onClose={handleOpenSuccessBuyDialogClose} title="Success">
        You succesfully confirmed purchase of your product.
      </InformationDialog>
      <InformationDialog open={openBalanceDialog} onClose={handleOpenBalanceDialogClose} title="Error">
        Your balance is too low for this product
      </InformationDialog>
      <InformationDialog open={openSuccessDeleteDialog} onClose={handleOpenSuccessDeleteDialogClose} title="Success">
        You succesfully delete product.
      </InformationDialog>
      <InformationDialog open={openErrorDialog} onClose={handleOpenErrorDialog} title="Error">
        {errorMessage}
      </InformationDialog>
      <DecisionDialog
        open={openDeleteDecisionDialog}
        onAccept={deleteUserProduct}
        onDecline={toggleOpenDeleteDecisionDialog}
      >
        <b>Are you sure</b> you want to delete {product?.title}?
      </DecisionDialog>
      <DecisionDialog
        open={openUnreserveDecisionDialog}
        onAccept={unreserveProduct}
        onDecline={toggleOpenUnreserveDecisionDialog}
      >
        <b>Are you sure</b> you want to remove reservation for {product?.title}?
      </DecisionDialog>
    </>
  );
};
