export const BottomNavigationPaths = [
  { url: '/ecoswap/category', value: 1 },
  { url: '/ecoswap/addproduct', value: 2 },
  { url: '/ecoswap/profile', value: 3 },
  { url: '/home', value: 0 },
  { url: '/gogreen/home', value: 1 },
  { url: '/gogreen/profile', value: 2 },
  { url: '/gogreen/activityFeed', value: 3 },
  { url: '/gogreen/challenges', value: 4 },
  { url: '/home/profile', value: 1 },
  { url: '/home/contactUs', value: 2 },
  { url: '/home/terms', value: 3 },
];
