class FetchActivitiesByVisibilityParams {
  constructor(
    public visibility: string,
    public sortDirection: 'ASC' | 'DESC',
    public limit: number,
    public nextToken: any,
    public createdAt?: any,
    public filter?: any,
  ) {}
}

export default FetchActivitiesByVisibilityParams;
