import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CreateActivityTypeInput, GetChallengeQuery } from '../../API';
import { fetchChallengeWithActivityTypes } from '../../common/api/apiclient';
import ActivityTypeBox from '../../components/activities/ActivityTypeBox';
import { ChallengeHeader } from '../../components/headers/ChallengeHeader';
import { LoadingBox } from '../../components/LoadingBox/LoadingBox';

interface ParamTypes {
  id: string;
}

function ChallengeActivityTypesPage() {
  const { id } = useParams<ParamTypes>();
  const [challenge, setChallenge] = useState<GetChallengeQuery['getChallenge']>();

  useEffect(() => {
    const loadChallenge = async () => {
      const loadedChallenge = await fetchChallengeWithActivityTypes(id);
      setChallenge(loadedChallenge?.getChallenge);
    };
    loadChallenge();
  }, [id]);

  return (
    <>
      <ChallengeHeader challenge={challenge} title="Log your progress" text="Pick an activity and track your actions" />
      <h3 style={{ padding: '0 25px' }}>Pick activity</h3>
      <Grid container style={{ justifyContent: 'center', paddingBottom: '50px' }}>
        {challenge ? (
          <>
            {challenge.activityTypes?.items?.map((activityType, i) => (
              <Grid item xs={12} key={`${challenge?.id}${i}`}>
                <ActivityTypeBox key={activityType?.id} activityType={activityType as CreateActivityTypeInput} />
              </Grid>
            ))}
          </>
        ) : (
          <LoadingBox />
        )}
      </Grid>
    </>
  );
}

export default ChallengeActivityTypesPage;
