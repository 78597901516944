import React, { useState } from 'react';
import { createStyles, makeStyles, Grid, Theme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { TutorialDialog } from '../dialogs/TutorialDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      justifyItems: 'center',
      cursor: 'default',
      position: 'relative',
    },
    button: {
      height: '3rem',
      width: '11rem',
      display: 'inline-block',
      margin: '5px',
      backgroundColor: '#7500c0',
      border: 'none',
      color: 'white',
      borderRadius: 12,
      fontWeight: 'bolder',
      fontSize: 'large',
    },
    icon: {
      width: '4vw',
      [theme.breakpoints.down('lg')]: {
        width: '6vw',
      },
      [theme.breakpoints.down('md')]: {
        width: '9vw',
      },
      [theme.breakpoints.down('sm')]: {
        width: '12vw',
      },
      [theme.breakpoints.down('xs')]: {
        width: '15vw',
      },
    },
    flex: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridTemplateRows: '1fr',
      justifyItems: 'end',
      width: '11rem',
      alignItems: 'end',
    },
    infoIcon: {
      cursor: 'pointer',
      borderRadius: '15px',
    },
    hoverInfo: {
      border: 'solid #7500C0',
      padding: '10px',
      position: 'absolute',
      top: '100%',
      marginRight: '5px',
      borderRadius: '40px',
      fontFamily: 'Graphik',
      fontSize: 'larger',
      maxWidth: '25vw',
    },
  }),
);

interface Props {
  title: string;
  url: string;
  icon: string;
  description: string;
}

export const AppPicker = ({ title, url, icon, description }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const handleMouse = () => {
    setIsHovering(!isHovering);
  };

  const toggleDialog = () => {
    setIsDialogOpened(!isDialogOpened);
  };

  return (
    <Grid item lg={6} md={6} sm={6} xs={12} className={classes.container}>
      <div className={classes.flex}>
        <div />
        <img src={icon} className={classes.icon} alt="Accenture" />
        <div className={classes.infoIcon}>
          <InfoIcon onClick={toggleDialog} />
        </div>
      </div>

      <button
        onClick={() => history.push(url)}
        className={classes.button}
        onFocus={handleMouse}
        onMouseOver={handleMouse}
        onMouseOut={handleMouse}
        onBlur={handleMouse}
        type="button"
      >
        {title}
      </button>
      {isHovering && <div className={classes.hoverInfo}>{description}</div>}
      <TutorialDialog open={isDialogOpened} onClose={toggleDialog} title="Information">
        {description}
      </TutorialDialog>
    </Grid>
  );
};
