import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { createStyles, makeStyles, Grid } from '@material-ui/core';
import { EmojiEvents } from '@material-ui/icons';
import { ChallengeByActiveAndStartDateQuery } from '../../API';
import { fetchActiveChallengesListByDate, fetchChallengeLeaderboardEntries } from '../../common/api/apiclient';
import AddActivityButton from '../../components/activities/AddActivityButton';
import { ChallengeHeader } from '../../components/headers/ChallengeHeader';
import FetchActiveChallengesParams from '../../utils/FetchActiveChallengesParams';
import FetchLeaderboardParams from '../../utils/FetchLeaderboardParams';
import { LoadingBox } from '../../components/LoadingBox/LoadingBox';
import { GoGreenHeader } from '../../components/headers/GoGreenHeader';

const useStyles = makeStyles(() =>
  createStyles({
    positon: {
      fontWeight: 'bold',
    },
    points: {
      color: '#7500C0',
      fontWeight: 600,
    },
  }),
);

export function LeaderboardPage() {
  const classes = useStyles();
  const [challengeList, setChallengeList] = useState<
    ChallengeByActiveAndStartDateQuery['challengeByActiveAndStartDate']
  >();
  const [total, setTotal] = useState(0);
  const [nextToken, setNextToken] = useState(null);
  const items = useRef<any>([]);
  const loading = useRef(false);

  const loadChallengeList = useCallback(async () => {
    const fetchParams = new FetchActiveChallengesParams('true', 'DESC', 10, null);
    const loadedChallengeList = await fetchActiveChallengesListByDate(fetchParams);
    setChallengeList(loadedChallengeList);
  }, []);

  useEffect(() => {
    loadChallengeList();
  }, [loadChallengeList]);

  const loadMore = useCallback(
    async (currentToken) => {
      if (loading.current || challengeList == null) {
        return;
      }

      loading.current = true;
      const fetchParams = new FetchLeaderboardParams(challengeList?.items?.[0]?.id, 'DESC', 20, currentToken);
      const loadedUsersList = await fetchChallengeLeaderboardEntries(fetchParams);
      items.current = [...items.current, ...loadedUsersList?.leaderboardEntryByChallengeAndPoints.items];
      setNextToken(loadedUsersList?.leaderboardEntryByChallengeAndPoints.nextToken);
      loading.current = false;
      setTotal(items.current.length);
    },
    [challengeList],
  );

  useEffect(() => {
    loadMore(null);
  }, [loadMore]);

  const renderTableRow = (index) => {
    return (
      <>
        {items.current[index] && (
          <Grid container key={index}>
            <Grid item xs={2}>
              <h5 className={classes.positon}>{index + 1}.</h5>
            </Grid>
            <Grid item xs={8}>
              <h5>{items.current[index].name}</h5>
            </Grid>
            <Grid item xs={2}>
              <h5 className={classes.points}>{items.current[index].totalPoints}</h5>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  return (
    <Grid style={{ position: 'relative' }}>
      <GoGreenHeader icon={EmojiEvents} title="Leaderboard" />
      {challengeList ? (
        <Grid item>
          {challengeList?.items?.[0]?.id && (
            <ChallengeHeader
              challenge={challengeList?.items[0] as any}
              title="Results of Challenges"
              text="Compete with others"
            />
          )}
          <Virtuoso
            style={{ height: '50vh', marginLeft: '20px' }}
            overscan={100}
            totalCount={total}
            item={renderTableRow}
            endReached={nextToken ? () => loadMore(nextToken) : undefined}
            footer={() => {
              return <>{nextToken && <div style={{ padding: '2rem', textAlign: 'center' }}>Loading...</div>}</>;
            }}
          />
        </Grid>
      ) : (
        <LoadingBox />
      )}
      {challengeList?.items?.[0]?.id && <AddActivityButton challengeId={challengeList?.items[0]?.id} />}
    </Grid>
  );
}
