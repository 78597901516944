export const getChallengeWithLeaderboard = /* GraphQL */ `
  query getChallengeWithLeaderboard($id: ID!) {
    getChallenge(id: $id) {
      id
      name
      active
      startDate
      endDate
      description
      leaderboard {
        items {
          id
          name
          email
          challengeID
          totalPoints
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const getChallengeWithActivityTypes = /* GraphQL */ `
  query getChallengeWithActivityTypes($id: ID!) {
    getChallenge(id: $id) {
      id
      name
      active
      startDate
      draft
      owner
      editors
      endDate
      description
      activityTypes {
        items {
          id
          challengeID
          name
          pointsPerAmount
          amountDescription
          description
          unit
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
