import React from 'react';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      alignSelf: 'center',
      width: 'auto',
      height: '6vh',
    },
    text: {
      color: 'black',
      [theme.breakpoints.down('md')]: {
        fontSize: '20px',
      },
    },
    item: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      borderRadius: '15px',
      textDecoration: 'none !important',

      [theme.breakpoints.up('lg')]: {
        '&:hover': {
          boxShadow: '1px 1.5px 1.5px 1.5px #8b8c8b',
        },
      },
    },
  }),
);

interface Props {
  category: String;
}

export const CategoryBox = ({ category }: Props) => {
  const classes = useStyles();

  return (
    <>
      <Link className={classes.item} to={`/ecoswap/products?category=${category.toLowerCase()}`}>
        <img
          alt="product"
          className={classes.image}
          src={`${process.env.PUBLIC_URL}/assets/categoryIcons/${category.toLowerCase()}.png`}
        />
        <h3 className={classes.text}>{category}</h3>
      </Link>
    </>
  );
};
