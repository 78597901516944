import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, DialogActions, Dialog } from '@material-ui/core';

const useStyles = makeStyles({
  dialog: {
    margin: '30px 10px',
    textAlign: 'center',
    fontFamily: 'Graphik',
  },
  title: {
    textAlign: 'center',
  },
  infoButton: {
    marginRight: '15px',
    color: 'white',
    backgroundColor: '#0000FF',
    '&:hover': {
      backgroundColor: '#0000FF95',
    },
  },
});

interface Props {
  onClose: () => void;
  open: boolean;
  title: string;
  children: ReactNode;
}

export const TutorialDialog = ({ onClose, open, title, children }: Props) => {
  const classes = useStyles();

  return (
    <Dialog fullWidth maxWidth="md" onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <div className={classes.dialog}>
        <h3>{title.toUpperCase()}</h3>
        <br />
        <h4>{children}</h4>
        <DialogActions>
          <Button size="large" onClick={onClose} className={classes.infoButton}>
            OK
          </Button>
        </DialogActions>
        <br />
      </div>
    </Dialog>
  );
};
