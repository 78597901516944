/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getActivity = /* GraphQL */ `
  query GetActivity($id: ID!) {
    getActivity(id: $id) {
      id
      activityTypeId
      email
      userName
      amount
      description
      points
      activityDate
      visibility
      imageKey
      imageKeys
      createdAt
      updatedAt
      owner
      activityType {
        id
        challengeID
        name
        pointsPerAmount
        description
        editors
        url
        urlDescription
        amountDescription
        unit
        order
        createdAt
        updatedAt
        owner
        challenge {
          id
          name
          active
          editors
          draft
          startDate
          endDate
          description
          createdAt
          updatedAt
          owner
        }
      }
    }
  }
`;
export const listActivitys = /* GraphQL */ `
  query ListActivitys(
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        activityTypeId
        email
        userName
        amount
        description
        points
        activityDate
        visibility
        imageKey
        imageKeys
        createdAt
        updatedAt
        owner
        activityType {
          id
          challengeID
          name
          pointsPerAmount
          description
          editors
          url
          urlDescription
          amountDescription
          unit
          order
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const activityByEmailAndActivityDate = /* GraphQL */ `
  query ActivityByEmailAndActivityDate(
    $email: String
    $activityDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activityByEmailAndActivityDate(
      email: $email
      activityDate: $activityDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        activityTypeId
        email
        userName
        amount
        description
        points
        activityDate
        visibility
        imageKey
        imageKeys
        createdAt
        updatedAt
        owner
        activityType {
          id
          challengeID
          name
          pointsPerAmount
          description
          editors
          url
          urlDescription
          amountDescription
          unit
          order
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const activityByVisibilityAndCreatedAt = /* GraphQL */ `
  query ActivityByVisibilityAndCreatedAt(
    $visibility: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activityByVisibilityAndCreatedAt(
      visibility: $visibility
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        activityTypeId
        email
        userName
        amount
        description
        points
        activityDate
        visibility
        imageKey
        imageKeys
        createdAt
        updatedAt
        owner
        activityType {
          id
          challengeID
          name
          pointsPerAmount
          description
          editors
          url
          urlDescription
          amountDescription
          unit
          order
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const getActivityType = /* GraphQL */ `
  query GetActivityType($id: ID!) {
    getActivityType(id: $id) {
      id
      challengeID
      name
      pointsPerAmount
      description
      editors
      url
      urlDescription
      amountDescription
      unit
      order
      createdAt
      updatedAt
      owner
      challenge {
        id
        name
        active
        editors
        draft
        startDate
        endDate
        description
        createdAt
        updatedAt
        activityTypes {
          nextToken
        }
        owner
        leaderboard {
          nextToken
        }
      }
    }
  }
`;
export const listActivityTypes = /* GraphQL */ `
  query ListActivityTypes(
    $filter: ModelActivityTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivityTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        challengeID
        name
        pointsPerAmount
        description
        editors
        url
        urlDescription
        amountDescription
        unit
        order
        createdAt
        updatedAt
        owner
        challenge {
          id
          name
          active
          editors
          draft
          startDate
          endDate
          description
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const getChallenge = /* GraphQL */ `
  query GetChallenge($id: ID!) {
    getChallenge(id: $id) {
      id
      name
      active
      editors
      draft
      startDate
      endDate
      description
      createdAt
      updatedAt
      activityTypes {
        items {
          id
          challengeID
          name
          pointsPerAmount
          description
          editors
          url
          urlDescription
          amountDescription
          unit
          order
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
      leaderboard {
        items {
          id
          name
          email
          owner
          challengeID
          totalPoints
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listChallenges = /* GraphQL */ `
  query ListChallenges(
    $filter: ModelChallengeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChallenges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        active
        editors
        draft
        startDate
        endDate
        description
        createdAt
        updatedAt
        activityTypes {
          nextToken
        }
        owner
        leaderboard {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const challengeByActiveAndStartDate = /* GraphQL */ `
  query ChallengeByActiveAndStartDate(
    $active: String
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChallengeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    challengeByActiveAndStartDate(
      active: $active
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        active
        editors
        draft
        startDate
        endDate
        description
        createdAt
        updatedAt
        activityTypes {
          nextToken
        }
        owner
        leaderboard {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getLeaderboardEntry = /* GraphQL */ `
  query GetLeaderboardEntry($id: ID!) {
    getLeaderboardEntry(id: $id) {
      id
      name
      email
      owner
      challengeID
      totalPoints
      createdAt
      updatedAt
      challenge {
        id
        name
        active
        editors
        draft
        startDate
        endDate
        description
        createdAt
        updatedAt
        activityTypes {
          nextToken
        }
        owner
        leaderboard {
          nextToken
        }
      }
    }
  }
`;
export const listLeaderboardEntrys = /* GraphQL */ `
  query ListLeaderboardEntrys(
    $filter: ModelLeaderboardEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeaderboardEntrys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        owner
        challengeID
        totalPoints
        createdAt
        updatedAt
        challenge {
          id
          name
          active
          editors
          draft
          startDate
          endDate
          description
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const leaderboardEntryByChallengeAndPoints = /* GraphQL */ `
  query LeaderboardEntryByChallengeAndPoints(
    $challengeID: ID
    $totalPoints: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeaderboardEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leaderboardEntryByChallengeAndPoints(
      challengeID: $challengeID
      totalPoints: $totalPoints
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        owner
        challengeID
        totalPoints
        createdAt
        updatedAt
        challenge {
          id
          name
          active
          editors
          draft
          startDate
          endDate
          description
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const leaderboardEntryByChallengeAndEmail = /* GraphQL */ `
  query LeaderboardEntryByChallengeAndEmail(
    $challengeID: ID
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeaderboardEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leaderboardEntryByChallengeAndEmail(
      challengeID: $challengeID
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        owner
        challengeID
        totalPoints
        createdAt
        updatedAt
        challenge {
          id
          name
          active
          editors
          draft
          startDate
          endDate
          description
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const leaderboardEntryByChallengeAndOwner = /* GraphQL */ `
  query LeaderboardEntryByChallengeAndOwner(
    $challengeID: ID
    $owner: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLeaderboardEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    leaderboardEntryByChallengeAndOwner(
      challengeID: $challengeID
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        owner
        challengeID
        totalPoints
        createdAt
        updatedAt
        challenge {
          id
          name
          active
          editors
          draft
          startDate
          endDate
          description
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const getProductInput = /* GraphQL */ `
  query GetProductInput($id: ID!) {
    getProductInput(id: $id) {
      id
      title
      email
      category
      price
      quality
      imageKey
      imageKeys
      description
      date
      size
      gender
      material
      reservedFor
      subcategory
      sold
      isInOffer
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listProductInputs = /* GraphQL */ `
  query ListProductInputs(
    $filter: ModelProductInputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductInputs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        email
        category
        price
        quality
        imageKey
        imageKeys
        description
        date
        size
        gender
        material
        reservedFor
        subcategory
        sold
        isInOffer
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const productByEmail = /* GraphQL */ `
  query ProductByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelProductInputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        email
        category
        price
        quality
        imageKey
        imageKeys
        description
        date
        size
        gender
        material
        reservedFor
        subcategory
        sold
        isInOffer
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const productByCategory = /* GraphQL */ `
  query ProductByCategory(
    $category: String
    $sortDirection: ModelSortDirection
    $filter: ModelProductInputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productByCategory(
      category: $category
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        email
        category
        price
        quality
        imageKey
        imageKeys
        description
        date
        size
        gender
        material
        reservedFor
        subcategory
        sold
        isInOffer
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const productBySubcategory = /* GraphQL */ `
  query ProductBySubcategory(
    $subcategory: String
    $sortDirection: ModelSortDirection
    $filter: ModelProductInputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productBySubcategory(
      subcategory: $subcategory
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        email
        category
        price
        quality
        imageKey
        imageKeys
        description
        date
        size
        gender
        material
        reservedFor
        subcategory
        sold
        isInOffer
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      email
      balance
      balanceOnHold
      createdAt
      updatedAt
      products {
        items {
          id
          title
          email
          category
          price
          quality
          imageKey
          imageKeys
          description
          date
          size
          gender
          material
          reservedFor
          subcategory
          sold
          isInOffer
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
      transactionsFrom {
        items {
          id
          sentFromEmail
          sentToEmail
          howMuch
          whatFor
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      transactionsTo {
        items {
          id
          sentFromEmail
          sentToEmail
          howMuch
          whatFor
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        balance
        balanceOnHold
        createdAt
        updatedAt
        products {
          nextToken
        }
        owner
        transactionsFrom {
          nextToken
        }
        transactionsTo {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const profileByEmail = /* GraphQL */ `
  query ProfileByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profileByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        balance
        balanceOnHold
        createdAt
        updatedAt
        products {
          nextToken
        }
        owner
        transactionsFrom {
          nextToken
        }
        transactionsTo {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      sentFromEmail
      sentToEmail
      howMuch
      whatFor
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sentFromEmail
        sentToEmail
        howMuch
        whatFor
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const transactionBySentFrom = /* GraphQL */ `
  query TransactionBySentFrom(
    $sentFromEmail: String
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionBySentFrom(
      sentFromEmail: $sentFromEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sentFromEmail
        sentToEmail
        howMuch
        whatFor
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const transactionBySentTo = /* GraphQL */ `
  query TransactionBySentTo(
    $sentToEmail: String
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionBySentTo(
      sentToEmail: $sentToEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sentFromEmail
        sentToEmail
        howMuch
        whatFor
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
