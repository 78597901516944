import React from 'react';
import { useHistory } from 'react-router-dom';
import { CreateActivityTypeInput } from '../../API';
import { ChallengeBox } from '../ChallengeBox/ChallengeBox';

interface Props {
  activityType: CreateActivityTypeInput;
}

export default function ActivityTypeBox({ activityType }: Props) {
  const history = useHistory();
  const navigateToAddActivity = () => {
    history.push(`/gogreen/activityTypes/${activityType.id}/newActivity`);
  };
  return (
    <ChallengeBox
      isActive
      title={activityType.name}
      subtitle={`${activityType.description}`}
      onClick={navigateToAddActivity}
    />
  );
}
