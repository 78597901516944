import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import Logo from '../../icons/GoGreenLogo.png';

const useStyles = makeStyles(() =>
  createStyles({
    headerTitle: {
      color: '#7500C0',
      fontSize: '35px',
    },
  }),
);

export const EcoswapHeader = () => {
  const classes = useStyles();
  return (
    <>
      <img src={Logo} alt="Logo" style={{ width: '13em' }} />
      <h3 className={classes.headerTitle}>
        <b>EcoSwap</b>
      </h3>
    </>
  );
};
