import { Container, Grid, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DatePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import { addChallenge } from '../../common/api/apiclient';
import { CreateChallengeInput } from '../../API';
import { GetLocalDateTime } from '../../utils/TimeFormatter';

function ChallengeCreatePage() {
  const history = useHistory();

  const [challengeName, setChallengeName] = useState<string>('');
  const [challengeDescription, setChallengeDescription] = useState<string>('');
  const [selectedFromDate, handleFromDateChange] = useState<MaterialUiPickersDate>(GetLocalDateTime());
  const [selectedEndDate, handleEndDateChange] = useState<MaterialUiPickersDate>(GetLocalDateTime());

  const handleNameChange = ({ target }) => {
    setChallengeName(target.value);
  };

  const handleDescriptionChange = ({ target }) => {
    setChallengeDescription(target.value);
  };

  async function handleUpdateChallenge() {
    const createChallengeInput: CreateChallengeInput = {
      active: 'true',
      draft: 'true',
      description: challengeDescription,
      endDate: selectedEndDate?.toISODate(),
      name: challengeName,
      startDate: selectedFromDate?.toISODate(),
    };

    try {
      const id = await addChallenge(createChallengeInput);
      history.push(`/admin/edit/challenges/${id}`);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div>
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h3 color="textSecondary">Create Challenge</h3>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DatePicker
              minDate={new Date('2022-4-1')}
              label="Challenge 'From' Date"
              value={selectedFromDate}
              onChange={handleFromDateChange}
              format="dd.LL.yyyy"
              inputVariant="outlined"
              disableToolbar
              variant="inline"
              autoOk
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DatePicker
              minDate={new Date('2022-4-1')}
              label="Challenge 'End' Date"
              value={selectedEndDate}
              onChange={handleEndDateChange}
              format="dd.LL.yyyy"
              inputVariant="outlined"
              disableToolbar
              variant="inline"
              autoOk
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField label="Edit Title" variant="outlined" value={challengeName} onChange={handleNameChange} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Edit Description"
              variant="outlined"
              value={challengeDescription}
              onChange={handleDescriptionChange}
            />
          </Grid>
          <Grid item>
            <Button color="primary" fullWidth variant="contained" onClick={handleUpdateChallenge}>
              Create Challenge
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default ChallengeCreatePage;
