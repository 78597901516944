import React, { Dispatch, SetStateAction } from 'react';
import { createStyles, makeStyles, TextField, MenuItem, Theme } from '@material-ui/core';

const Materials = [
  'Synthetics',
  'Cotton',
  'Wool',
  'Cellulosics',
  'Leather',
  'Silk',
  'Denim',
  'Velvet',
  'Linen',
  'Suede',
  'Other',
];
const Genders = ['Male', 'Female', 'Kids-Boy', 'Kids-Girl'];
const Sizes = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL'];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullWidth: {
      width: '80%',
      margin: '0 10%',
      marginTop: '10px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  }),
);

type IProps = {
  subCategory: string;
  size: string;
  setSize: Dispatch<SetStateAction<string>>;
  gender: any;
  setGender: Dispatch<SetStateAction<string>>;
  material: any;
  setMaterial: Dispatch<SetStateAction<string>>;
};

export const ClothesForm = ({ size, setSize, gender, setGender, material, setMaterial }: IProps) => {
  const classes = useStyles();

  const handleSizeChange = ({ target }) => {
    setSize(target.value);
  };

  const handleGenderChange = ({ target }) => {
    setGender(target.value);
  };

  const handleMaterialChange = ({ target }) => {
    setMaterial(target.value);
  };

  return (
    <>
      <TextField
        id="gender"
        label="Gender"
        select
        value={gender}
        onChange={handleGenderChange}
        className={classes.fullWidth}
      >
        {Genders.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      {gender === 'Kids-Boy' || gender === 'Kids-Girl' ? (
        <TextField
          id="size"
          label="Size"
          value={size}
          onChange={handleSizeChange}
          className={classes.fullWidth}
          required
        />
      ) : (
        <TextField id="size" label="Size" select value={size} onChange={handleSizeChange} className={classes.fullWidth}>
          {Sizes.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      )}
      <TextField
        id="material"
        label="Material"
        select
        value={material}
        onChange={handleMaterialChange}
        className={classes.fullWidth}
      >
        {Materials.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};
