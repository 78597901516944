/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    aws_cognito_identity_pool_id: 'eu-west-1:91d1bdd4-d680-4a4e-8a91-c5450406b8bb',
    aws_cognito_region: 'eu-west-1',
    aws_user_pools_id: 'eu-west-1_6IByPaRZ3',
    aws_user_pools_web_client_id: 'o71ke4daibtjf15eh5j4cv7cg',
    oauth: {
        domain: 'gogreen-slovakia.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'profile', 'openid'],
        // redirectSignIn: 'http://localhost:3000/',
        redirectSignIn: 'https://gogreenslovakia.com/',
        responseType: 'token',
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://3g5rk2t7orhnrpnwps2w7cw5dq.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "activity-images121003-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
