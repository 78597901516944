import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import { Grid } from '@material-ui/core';

interface ListProps {
  images: [string, string][]; // tuple containing key and preview
  removeFileHandler: (arg0: string) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    close: {
      position: 'absolute',
      top: '-25px',
      left: '85%',
      backgroundColor: 'transparent',
      outline: 'none',
      border: 'none',
    },
    container: {
      position: 'relative',
      width: '50%',
    },
    image: {
      width: '100%',
      height: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    fullWidth: {
      width: '100%',
      marginBottom: '3%',
    },
    grid: {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);

export default function ListOfPhotoPreviews({ images, removeFileHandler }: ListProps) {
  const classes = useStyles();
  console.log(images);
  return (
    <>
      <Grid container spacing={3} className={classes.fullWidth}>
        {images.map(([key, image]: [string, string]) => (
          <Grid item className={classes.grid} lg={3} md={4} xs={4} key={key}>
            <div className={classes.container}>
              <img src={image} className={classes.image} alt="" />
              <button type="button" id="remove-button" className={classes.close} onClick={() => removeFileHandler(key)}>
                <span>
                  <IconButton color="primary" aria-label="remove picture" component="span">
                    <ClearIcon />
                  </IconButton>
                </span>
              </button>
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
