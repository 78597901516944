import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

class IconPack {
  static HomeIcon = () => {
    return (
      <SvgIcon>
        <svg id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <path d="M16.61,2.21a1,1,0,0,0-1.24,0L1,13.42,2.24,15,4,13.62V26a2,2,0,0,0,2,2H26a2,2,0,0,0,2-2V13.63L29.76,15,31,13.43ZM18,26H14V18h4Zm2,0h0V18a2,2,0,0,0-2-2H14a2,2,0,0,0-2,2v8H6V12.06L16,4.27l10,7.8V26Z" />
        </svg>
      </SvgIcon>
    );
  };

  static SharedFeedIcon = () => {
    return (
      <SvgIcon>
        <svg id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <path d="M8 8H6v7c0 1.1.9 2 2 2h9v-2H8V8z" />
          <path d="M20 3h-8c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 8h-8V7h8v4zM4 12H2v7c0 1.1.9 2 2 2h9v-2H4v-7z" />
        </svg>
      </SvgIcon>
    );
  };

  static ChallengesIcon = () => {
    return (
      <SvgIcon>
        <svg id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <path d="M26,7H24V6a2.0023,2.0023,0,0,0-2-2H10A2.0023,2.0023,0,0,0,8,6V7H6A2.0023,2.0023,0,0,0,4,9v3a4.0045,4.0045,0,0,0,4,4h.322A8.1689,8.1689,0,0,0,15,21.9341V26H10v2H22V26H17V21.9311A7.9661,7.9661,0,0,0,23.74,16H24a4.0045,4.0045,0,0,0,4-4V9A2.0023,2.0023,0,0,0,26,7ZM8,14a2.0023,2.0023,0,0,1-2-2V9H8Zm14,0a6,6,0,0,1-6.1855,5.9971A6.1991,6.1991,0,0,1,10,13.7065V6H22Zm4-2a2.0023,2.0023,0,0,1-2,2V9h2Z" />
        </svg>
      </SvgIcon>
    );
  };

  static TermsIcon = () => {
    return (
      <SvgIcon>
        <svg id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <rect x="6" y="16" width="6" height="2" />
          <rect x="6" y="12" width="10" height="2" />
          <rect x="6" y="8" width="10" height="2" />
          <path d="M14,26H4V6H28V16h2V6a2,2,0,0,0-2-2H4A2,2,0,0,0,2,6V26a2,2,0,0,0,2,2H14Z" />
          <polygon points="22 25.59 19.41 23 18 24.41 22 28.41 30 20.41 28.59 19 22 25.59" />
        </svg>
      </SvgIcon>
    );
  };

  static ContactIcon = () => {
    return (
      <SvgIcon>
        <svg id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <path d="M28,6H4A2,2,0,0,0,2,8V24a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V8A2,2,0,0,0,28,6ZM25.8,8,16,14.78,6.2,8ZM4,24V8.91l11.43,7.91a1,1,0,0,0,1.14,0L28,8.91V24Z" />
        </svg>
      </SvgIcon>
    );
  };

  static AddIcon = () => {
    return (
      <SvgIcon>
        <svg version="1.1" id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <polygon points="17,15 17,8 15,8 15,15 8,15 8,17 15,17 15,24 17,24 17,17 24,17 24,15 " />
          <path d="M26,4H6A2,2,0,0,0,4,6V26a2,2,0,0,0,2,2H26a2,2,0,0,0,2-2V6A2,2,0,0,0,26,4ZM6,26V6H26V26Z" />
          {/* <rect className="st0" width="32" height="32" /> */}
        </svg>
      </SvgIcon>
    );
  };

  static LeafLeftIcon = () => {
    return (
      <SvgIcon>
        <svg xmlns="http://www.w3.org/2000/svg">
          <path
            transform="translate(12, 12) scale(-1 1) translate(-12, -12)"
            d="M17,8C8,10 5.9,16.17 3.82,21.34L5.71,22L6.66,19.7C7.14,19.87 7.64,20 8,20C19,20 22,3 22,3C21,5 14,5.25 9,6.25C4,7.25 2,11.5 2,13.5C2,15.5 3.75,17.25 3.75,17.25C7,8 17,8 17,8Z"
            fill="#7500C0"
          />
        </svg>
      </SvgIcon>
    );
  };

  static LeafRightIcon = () => {
    return (
      <SvgIcon>
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M17,8C8,10 5.9,16.17 3.82,21.34L5.71,22L6.66,19.7C7.14,19.87 7.64,20 8,20C19,20 22,3 22,3C21,5 14,5.25 9,6.25C4,7.25 2,11.5 2,13.5C2,15.5 3.75,17.25 3.75,17.25C7,8 17,8 17,8Z" />
        </svg>
      </SvgIcon>
    );
  };

  static ToolsIcon = () => {
    return (
      <SvgIcon>
        <svg viewBox="17 15 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M23.75 17.1875V19.6219C22.3844 19.9187 21.375 21.1062 21.375 22.5312C21.375 23.9563 22.3844 25.1438 23.75 25.4406V33.8125H24.9375V25.4406C26.3031 25.1438 27.3125 23.9563 27.3125 22.5312C27.3125 21.1062 26.3031 19.9187 24.9375 19.6219V17.1875H23.75ZM26.125 22.5312C26.125 23.5406 25.3531 24.3125 24.3438 24.3125C23.3344 24.3125 22.5625 23.5406 22.5625 22.5312C22.5625 21.5219 23.3344 20.75 24.3438 20.75C25.3531 20.75 26.125 21.5219 26.125 22.5312Z"
            fill="#7500C0"
          />
          <path
            d="M33.25 33.8125V31.3781C34.6156 31.0812 35.625 29.8937 35.625 28.4688C35.625 27.0437 34.6156 25.8563 33.25 25.5594V17.1875H32.0625V25.5594C30.6969 25.8563 29.6875 27.0437 29.6875 28.4688C29.6875 29.8937 30.6969 31.0812 32.0625 31.3781V33.8125H33.25ZM30.875 28.4688C30.875 27.4594 31.6469 26.6875 32.6562 26.6875C33.6656 26.6875 34.4375 27.4594 34.4375 28.4688C34.4375 29.4781 33.6656 30.25 32.6562 30.25C31.6469 30.25 30.875 29.4781 30.875 28.4688Z"
            fill="#7500C0"
          />
        </svg>
      </SvgIcon>
    );
  };

  static HamburgerIcon = () => {
    return (
      <SvgIcon>
        <svg width="100" height="100" viewBox="0 0 100 100">
          <path
            className="line line1"
            d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
          />
          <path className="line line2" d="M 20,50 H 80" />
          <path
            className="line line3"
            d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
          />
        </svg>
      </SvgIcon>
    );
  };
}

export default IconPack;
