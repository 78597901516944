import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, createStyles, makeStyles, Theme, SvgIcon, Drawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { CategoryNavigation } from '../navigation/CategoryNavigation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hamburger: {
      paddingRight: '50px',
      display: 'flex',
      alignItems: 'center',
    },
    hamburgerIcon: {
      fontSize: '45px',
      display: 'none',
      zIndex: 5,
      color: '#7500C0',
      [theme.breakpoints.down('md')]: {
        display: 'flex',
      },
    },
    drawer: {
      width: '225px',
    },
  }),
);

export const HamburgerMenu = () => {
  const classes = useStyles();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currentCategory = params.get('category');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <Grid item className={classes.hamburger} onClick={toggleDrawer}>
      <SvgIcon component={MenuIcon} className={classes.hamburgerIcon} />
      <Drawer open={isDrawerOpen} anchor="right" onClose={toggleDrawer}>
        <div className={classes.drawer}>
          <CategoryNavigation currentCategory={currentCategory!} />
        </div>
      </Drawer>
    </Grid>
  );
};
