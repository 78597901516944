import { Grid } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import AccentureLogo from '../../icons/AccentureLogo.jpg';
import GreenWayIcon from '../../icons/GreenWayLogo.svg';

const useStyles = makeStyles(() => ({
  container: {
    padding: '0 25px',
    width: '100%',
  },
  title: {
    paddingTop: '10px',
  },
  logo: {
    verticalAlign: 'top',
    width: '15px',
    marginRight: '10px',
  },
  description: {
    display: 'inline-block',
    maxWidth: '90%',
  },
  bottomLogoWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    marginBottom: '60px',
  },
  bottomLogo: {
    width: 180,
    height: 60,
  },
}));

const TermsOfUsageBulletPoints = [
  'This mobile app is the intellectual property of Accenture and it is not allowed to copy its functionality, content or design.',
  'This application is intended solely for the use of Accenture Slovakia employees and should not be used by any third party.',
  'All challenges are organized by GreenWay, the environmental committee of Accenture Slovakia maintained under the ISO14001 certification.',
  'The winners might be requested to provide a proof of completed activities. If the amount of collected points will be suspicious for any reason, the organizing team reserves the right to disqualify any participant.',
];

const ConcactBulletPoints = [
  'In case of any technical difficulties or questions regarding the competition, please contact ba-security@accenture.com.',
  'For any other environmental questions contact Bratislava-GW@accenture.com.',
];

export function Terms() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <h2 className={classes.title}>Terms of use</h2>
        {TermsOfUsageBulletPoints.map((text) => (
          <div key={text}>
            <img className={classes.logo} src={AccentureLogo} alt="Accenture" />
            <h6 className={classes.description}>{text}</h6>
          </div>
        ))}
      </div>
      <div className={classes.container}>
        <h2 className={classes.title}>Contact us</h2>
        {ConcactBulletPoints.map((text) => (
          <div key={text}>
            <img className={classes.logo} src={AccentureLogo} alt="Accenture" />
            <h6 className={classes.description}>{text}</h6>
          </div>
        ))}
        <Grid container className={classes.bottomLogoWrapper}>
          <img className={classes.bottomLogo} src={GreenWayIcon} alt="Accenture" />
        </Grid>
      </div>
    </>
  );
}
