import { Grid, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { getCategoryProducts, getProducts, getUploadedImageUrl } from '../../common/api/apiclient';
import Product from '../../components/products/Product';
import ProductHeader from '../../components/headers/ProductHeader';
import { LoadingBox } from '../../components/LoadingBox/LoadingBox';
import { CategoryNavigation } from '../../components/navigation/CategoryNavigation';
import { SearchHeader } from '../../components/headers/SearchHeader';
import useWindowDimensions from '../../common/hooks/useWindowDimensions';
import { FormatSearchText } from '../../utils/FormatSearchText';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '0',
      margin: '0',
      overflow: 'hidden',
    },
    contentBox: {
      display: 'flex',
      marginLeft: '20px',
      marginTop: '15px',
      overflowY: 'scroll',
      height: '78%',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        marginLeft: '2%',
      },
    },
    menu: {
      width: '240px',
      fontSize: '25px',
      color: '#7500C0',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    products: {
      width: '100%',
      margin: '0 10px',
      marginBottom: '100px',
    },
    emptyProducts: {
      textAlign: 'center',
      width: 'inherit',
      display: 'flex',
      height: '45vh',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

export const ProductsPage = () => {
  const classes = useStyles();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currentCategory = params.get('category');
  const currentSearch = params.get('search');
  const [products, setProducts] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { width } = useWindowDimensions();

  const loadMore = useCallback(async () => {
    if (!currentCategory && !currentSearch) return;

    setIsLoading(true);
    const loadedProducts = currentCategory
      ? await getCategoryProducts(currentCategory[0].toUpperCase() + currentCategory.slice(1))
      : await getProducts();

    const loadedProductsWithAttachments = await Promise.all(
      loadedProducts.items.map(async (product) => ({
        ...product,
        attachmentUrls: await getUploadedImageUrl(product.imageKeys[0]),
      })),
    );
    let productToLoad = loadedProductsWithAttachments.filter((product: any) => !product.sold && product.isInOffer);

    if (currentSearch) {
      productToLoad = productToLoad.filter(
        (product: any) =>
          FormatSearchText(product.title).includes(FormatSearchText(currentSearch)) ||
          FormatSearchText(product.description).includes(FormatSearchText(currentSearch)),
      );
    }
    setProducts(productToLoad);
    setIsLoading(false);
  }, [currentCategory, currentSearch]);

  useEffect(() => {
    loadMore();
  }, [loadMore]);

  return (
    <div className={classes.container}>
      {width >= 1280 ? <ProductHeader /> : <SearchHeader hasAddProductButton={false} />}
      <div className={classes.contentBox}>
        <div className={classes.menu}>
          <CategoryNavigation currentCategory={currentCategory!} />
        </div>
        <Grid container spacing={3} className={classes.products}>
          {isLoading && <LoadingBox />}
          {Array.isArray(products) && products.length
            ? products.map(
                (product) =>
                  !product.sold && (
                    <Grid xl={2} lg={3} md={4} sm={6} xs={12} key={product.id} item>
                      <Product
                        title={product.title}
                        urls={product.attachmentUrls}
                        key={product.id}
                        id={product.id}
                        reservedFor={product.reservedFor}
                        owner={product.owner}
                        sold={product.sold}
                        price={product.price}
                      />
                    </Grid>
                  ),
              )
            : !isLoading && (
                <div className={classes.emptyProducts}>
                  <h1>No product has been found</h1>
                </div>
              )}
        </Grid>
      </div>
    </div>
  );
};
