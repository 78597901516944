import React, { ChangeEvent } from 'react';
import { Button, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import SelectPhoto from './SelectPhoto';
import ListOfPhotoPreviews from './PhotoPreview';
import { UploadFile, removeFileFromUpload } from '../products/AddProductHelpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    uploadBox: {
      width: '80%',
      margin: '0 10%',
      marginTop: '10px',
      marginBottom: '100px',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '25%',
      },
    },
    addButton: {
      color: 'white',
      float: 'right',
      backgroundColor: '#7500C0',
      '&:hover': {
        backgroundColor: '#7500C095',
      },
    },
    backButton: {
      color: 'white',
      backgroundColor: '#7500C0',
      '&:hover': {
        backgroundColor: '#7500C095',
      },
    },
  }),
);

interface Props {
  uploadFileHandler: (event: ChangeEvent<HTMLInputElement>) => void;
  uploadFiles: UploadFile[] | null;
  setUploadFiles: Function;
  isProduct: boolean;
  isUpdate: boolean;
}

export default function AddPhotos({ uploadFileHandler, uploadFiles, setUploadFiles, isProduct, isUpdate }: Props) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.uploadBox}>
      <SelectPhoto uploadFileHandler={uploadFileHandler} />
      {uploadFiles && (
        <ListOfPhotoPreviews
          images={uploadFiles.map((file) => [file.key, file.preview])}
          removeFileHandler={(key) => removeFileFromUpload(key, uploadFiles, setUploadFiles)}
        />
      )}
      <Button className={classes.addButton} variant="contained" type="submit">
        {isUpdate && 'Update Product'}
        {!isUpdate && (isProduct ? 'Add product' : 'Add activity')}
      </Button>
      <Button type="button" variant="contained" className={classes.backButton} onClick={() => history.goBack()}>
        Back
      </Button>
    </div>
  );
}
