import { Grid, Paper, createStyles, makeStyles, Theme, Collapse } from '@material-ui/core';
import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Carousel } from 'react-responsive-carousel';
import { findIcon } from '../../utils/IconMap';
import { ImageDialog } from '../dialogs/ImageDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: '10px auto',
      width: '75%',
      padding: '10px',
      MaxHeight: '160px',
      background: '#ffffff',
      borderRadius: '11px',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      boxSizing: 'border-box',
    },
    image: {
      marginLeft: '10px',
      [theme.breakpoints.down('xs')]: {
        width: '15vw',
      },
    },
    FontFamily1: {
      fontWeight: 'bold',
      fontSize: '15px',
      lineHeight: '14.3px',
      color: '#000000',
    },
    FontFamily2: {
      fontWeight: 600,
      lineHeight: '15px',
      margin: '5px 0px 0px 0px',
      color: '#000000',
    },
    points: {
      fontWeight: 600,
      color: '#000000',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      whiteSpace: 'nowrap',
      width: '100vw',
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
      },
    },
    expandButton: {
      backgroundColor: '#7500c0',
      border: 'none',
      color: 'white',
      borderRadius: 12,
      width: '10vw',
      minWidth: '35px',
      height: '5vh',
      fontWeight: 'bolder',
      float: 'right',
      alignSelf: 'center',
      [theme.breakpoints.down('md')]: {
        width: '13vw',
      },
      [theme.breakpoints.down('sm')]: {
        width: '20vw',
      },
    },
    pointsGrid: {
      flexBasis: '0',
      display: 'flex',
      width: '100vw',
      textAlign: 'center',
      alignSelf: 'center',
    },
    sideButtonGrid: {
      display: 'grid',
      alignSelf: 'center',
    },
    sideLayout: {
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    center: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    collapseImage: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    imgWrapper: {
      width: '30%',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        width: '40%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '50%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '80%',
      },
    },
    productImage: {
      maxWidth: '100%',
      objectFit: 'contain',
    },
    productImageDialog: {
      objectFit: 'contain',
      maxHeight: '75vh',
      width: '100%',
    },
  }),
);

export const ActivityBox = ({
  userName,
  activityType,
  activityAmount,
  activityAmountUnit,
  activityPointsEarned,
  activityDate,
  attachmentUrls,
  isOpen = false,
}) => {
  const classes = useStyles();
  const finalIcon = findIcon(activityType?.trim());
  const [open, setOpen] = useState(isOpen);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState();

  const toggleImage = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const toggleOpenImageDialog = (image: any) => {
    return () => {
      setSelectedImage(image);
      setOpenImageDialog(!openImageDialog);
    };
  };

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item xs={3} md={2} className={classes.center}>
          <img src={finalIcon} alt="logo" className={classes.image} />
        </Grid>
        <Grid xs={9} md={10} item container alignItems="center">
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs={7}>
              <div style={{ height: 'auto' }}>
                <h5 className={classes.FontFamily1}>{userName}</h5>
                {activityType && (
                  <h6 color="textSecondary" className={classes.FontFamily2}>
                    {activityType}
                  </h6>
                )}
                <h6 color="textSecondary" className={classes.FontFamily2}>
                  {`${activityAmount}x  ${activityAmountUnit}`}
                </h6>

                <h6 color="textSecondary">{activityDate}</h6>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={5} className={classes.sideLayout}>
            <Grid item xs={12} md={6} className={classes.pointsGrid}>
              <h4 className={classes.points}>{`${activityPointsEarned} Points`}</h4>
            </Grid>
            <Grid item xs={12} md={6} className={classes.sideButtonGrid}>
              {attachmentUrls && attachmentUrls?.length > 0 && (
                <button
                  onClick={toggleImage}
                  aria-controls="collapse-image"
                  aria-expanded={open}
                  className={classes.expandButton}
                  type="button"
                >
                  <span>
                    <ExpandMoreIcon
                      style={{ transform: open ? 'rotate(180deg)' : 'rotate(0)', transition: 'all 0.2s linear' }}
                    />
                  </span>
                </button>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Collapse in={open}>
          <div className={classes.collapseImage}>
            <Carousel emulateTouch swipeable showThumbs={false}>
              {attachmentUrls.map((image) => (
                <div className={classes.imgWrapper} key={image} onClick={toggleOpenImageDialog(image)} aria-hidden>
                  <img src={image} alt="activity" className={classes.productImage} />
                </div>
              ))}
            </Carousel>
          </div>
        </Collapse>
      </Grid>
      <ImageDialog open={openImageDialog} onClose={() => setOpenImageDialog(!openImageDialog)}>
        <img src={selectedImage} alt="activity" className={classes.productImageDialog} />
      </ImageDialog>
    </Paper>
  );
};
