import { createStyles, makeStyles, Grid, Theme } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import Logo from '../../icons/GoGreenLogo.png';
import EcoswapIcon from '../../icons/apps/ecoswap.png';
import MyChallangesIcon from '../../icons/apps/challanges.png';
import { AppPicker } from '../../components/AppPicker/AppPicker';
import { CreateProfileInput } from '../../API';
import { getUserProfileByEmail, createNewProfile } from '../../common/api/apiclient';
import { useAuthContext } from '../../common/context/AuthContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      justifyItems: 'center',
      textAlign: 'center',
      flexDirection: 'column',
      height: '100%',
      overflow: 'hidden',
    },
    subTitle: {
      marginTop: '1rem',
      color: 'rgba(0, 0, 0, 0.9)',
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    logo: {
      width: '50%',
      placeSelf: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '65%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '80%',
      },
    },
    appContainer: {
      marginTop: '3rem',
      justifyContent: 'center',
      justifyItems: 'center',
    },
  }),
);

export const Home = () => {
  const classes = useStyles();
  const { user } = useAuthContext();

  const getTokenPayload = useCallback(() => {
    return user?.getSignInUserSession()?.getIdToken().payload;
  }, [user]);

  useEffect(() => {
    const checkProfile = async () => {
      if (!user) return;

      const profile = await getUserProfileByEmail(getTokenPayload()?.email);
      if (!profile) {
        const userProfile: CreateProfileInput = {
          id: getTokenPayload()?.email,
          email: getTokenPayload()?.email,
          balance: 5,
          balanceOnHold: [0],
        };
        await createNewProfile(userProfile);
      }
    };

    checkProfile();
  }, [getTokenPayload, user]);

  return (
    <>
      <div className={classes.container}>
        <h1 className={classes.subTitle}>Welcome to</h1>
        <img src={Logo} className={classes.logo} alt="Accenture" />
        <Grid container className={classes.appContainer} spacing={2}>
          <AppPicker
            title="Challenges"
            url="/gogreen/home"
            icon={MyChallangesIcon}
            description="Explore and join eco-challenges, get inspired and behave even greener than before."
          />
          <AppPicker
            title="EcoSwap"
            url="/ecoswap/category"
            icon={EcoswapIcon}
            description="Exchange things with colleagues that you don't need or have never worn or used. Delight others with your items and choose something beneficial for yourself."
          />
        </Grid>
      </div>
    </>
  );
};
