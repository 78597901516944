import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, DialogActions, Dialog } from '@material-ui/core';

const useStyles = makeStyles({
  dialog: {
    margin: '30px 10px',
    textAlign: 'center',
    fontFamily: 'Graphik',
  },
  title: {
    textAlign: 'center',
  },
  confirmButton: {
    marginRight: '15px',
    color: 'white',
    backgroundColor: 'green',
    '&:hover': {
      backgroundColor: 'green',
    },
  },
  cancelButton: {
    marginRight: '15px',
    color: 'white',
    backgroundColor: 'red',
    '&:hover': {
      backgroundColor: 'red',
    },
  },
});

interface Props {
  onAccept: () => void;
  onDecline: () => void;
  open: boolean;
  children: ReactNode;
}

export const DecisionDialog = ({ onAccept, onDecline, open, children }: Props) => {
  const classes = useStyles();

  return (
    <Dialog fullWidth maxWidth="md" onClose={onDecline} aria-labelledby="simple-dialog-title" open={open}>
      <div className={classes.dialog}>
        <h4>{children}</h4>
        <DialogActions>
          <Button size="large" onClick={onDecline} className={classes.cancelButton}>
            Cancel
          </Button>
          <Button size="large" onClick={onAccept} className={classes.confirmButton}>
            Confirm
          </Button>
        </DialogActions>
        <br />
      </div>
    </Dialog>
  );
};
