import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import OwnerIcon from '../../icons/common/owner.png';
import SoldIcon from '../../icons/common/sold.png';
import ReservedIcon from '../../icons/common/reserved.png';
import ReservedUserIcon from '../../icons/common/reservedUser.png';
import { useAuthContext } from '../../common/context/AuthContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    productGrid: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      color: '#7500C0',
      [theme.breakpoints.down('xs')]: {
        alignItems: 'center',
      },
    },
    imageContainer: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      width: '100%',
      boxShadow: '7px 5px 15px 5px rgb(0 0 0 / 47%)',
      backgroundColor: 'white',
    },
    title: {
      fontSize: '20px',
      color: 'black',
      fontWeight: 'bold',
    },
    icon: {
      width: '50px',
      height: '50px',
      position: 'absolute',
      top: '-5px',
      zIndex: 2,
      borderRadius: '15px',
      background: 'mintcream',
    },
    ownerIcon: {
      right: '-10px',
      transform: 'rotate(25deg)',
    },
    reservedIcon: {
      left: '-10px',
      transform: 'rotate(-25deg)',
    },
    image: {
      objectFit: 'contain',
      width: '18em',
      height: '18em',
      background: 'white',
      '&:hover': {
        transform: 'scale(1.2)',
      },
    },
  }),
);

interface Props {
  title: String;
  urls: [];
  id: String;
  reservedFor: string;
  owner: string;
  sold: boolean;
  price: number;
}

export default function Product({ title, urls, id, reservedFor, owner, sold, price }: Props) {
  const classes = useStyles();
  const { user } = useAuthContext();
  const userMail = user?.getUsername();
  const isOwner = owner === userMail || owner === userMail!.split('_')[1];

  const getTokenPayload = useCallback(() => {
    return user?.getSignInUserSession()?.getIdToken().payload;
  }, [user]);

  return (
    <Grid item className={classes.productGrid}>
      <Grid className={classes.title}>
        <p>{title}</p>
        <p>
          {price} {price === 1 ? 'Point' : 'Points'}
        </p>
      </Grid>
      <div className={classes.imageContainer}>
        {sold && <img src={SoldIcon} className={`${classes.icon} ${classes.ownerIcon}`} alt="Sold icon" />}
        {isOwner && <img src={OwnerIcon} className={`${classes.icon} ${classes.ownerIcon}`} alt="Owner icon" />}
        {reservedFor && (
          <img
            src={reservedFor === getTokenPayload()?.email ? ReservedUserIcon : ReservedIcon}
            className={`${classes.icon} ${classes.reservedIcon}`}
            alt="Reserve icon"
          />
        )}

        <Link to={`/ecoswap/products/${id}`}>
          <img src={`${urls.toString()}`} alt="" className={classes.image} />
        </Link>
      </div>
    </Grid>
  );
}
