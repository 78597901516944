import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme, TextField, MenuItem, ListItemIcon, Checkbox, Box, Typography } from '@material-ui/core';
import { useAuthContext } from '../../common/context/AuthContext';
import { getProduct, getUploadedImageUrl, updateReserveProduct, uploadImage } from '../../common/api/apiclient';
import { Categories } from '../../components/products/ProductCategories';
import { ClothesForm } from '../../components/forms/ClothesForm';
import { UpdateProductInputInput } from '../../API';
import InformationDialog from '../../components/dialogs/InformationDialog';
import AddPhotos from '../../components/photos/AddPhotos';
import { selectImageForUpload, UploadFile } from '../../components/products/AddProductHelpers';
import {
  getSubcategories,
  getClothesSubcategoryMaxPrice,
  getCategoryMaxPrice,
} from '../../components/categories/Subcategories';
import { EcoswapHeader } from '../../components/headers/EcoswapHeader';

type Params = {
  id: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: '0',
      padding: '0',
      width: '100vw',
    },
    header: {
      paddingLeft: '5%',
    },
    title: {
      fontSize: '45px',
      fontWeight: 'bold',
      color: '#7500C0',
      marginLeft: '5%',
      marginTop: '25px',
    },
    description: {
      fontSize: '25px',
      fontWeight: 'bold',
      marginTop: '1em',
      marginBottom: '1em',
    },
    fullWidth: {
      width: '80%',
      margin: '0 10%',
      marginTop: '10px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    icon: {
      alignSelf: 'center',
      width: '3em',
      height: '3em',
      [theme.breakpoints.down('xs')]: {
        width: '2rem',
        height: '2rem',
      },
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '20px',
    },
  }),
);

const TypeOfQualities = ['New', 'Barely used', 'Used (not damaged)', 'Worn out'];

export const EditProduct = () => {
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams<Params>();
  const { user } = useAuthContext();
  const tokenPayload = user?.getSignInUserSession()?.getIdToken()?.payload;

  const [product, setProduct] = useState<any>();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState<string>('');
  const [quality, setQuality] = useState<string>('');
  const [size, setSize] = useState<string>('');
  const [gender, setGender] = useState<string>('');
  const [material, setMaterial] = useState<string>('');
  const [price, setPrice] = useState<number>(1);
  const [maxRange, setMaxRange] = useState(10);
  const [currentSubcategories, setCurrentSubcategories] = useState<string[]>([]);
  const [infoAboutImage, setInfoAboutImage] = useState('');
  const [openImagesDialog, setOpenImagesDialog] = useState(false);
  const [uploadFiles, setUploadFiles] = useState<UploadFile[] | null>([]);

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  const [checked, setChecked] = React.useState(false);

  const handleTitleChange = ({ target }) => {
    setTitle(target.value);
  };

  const handleDescriptionChange = ({ target }) => {
    setDescription(target.value);
  };
  const handleImagesDialogClose = () => {
    setOpenImagesDialog(false);
  };
  const handleCategoryChange = ({ target }) => {
    setCategory(target.value);
    setPrice(1);
    setQuality('');
    setSize('');
    setGender('');
    setSubcategory('');
    setCurrentSubcategories(getSubcategories(target.value)!);
    setMaxRange(getCategoryMaxPrice(target.value)!);
  };

  const handleSubcategoryChange = ({ target }) => {
    if (category === 'Clothes') {
      setMaxRange(getClothesSubcategoryMaxPrice(target.value)!);
    }
    setSubcategory(target.value);
  };

  const handleQualityChange = ({ target }) => {
    setQuality(target.value);
  };

  const handlePriceChange = ({ target }) => {
    setPrice(+target.value > 0 ? target.value : null);
  };

  const handleSuccessDialogClose = () => {
    setOpenSuccessDialog(false);
    history.push(`/ecoswap/products/${id}`);
  };

  const handleErrorDialogClose = () => {
    setOpenErrorDialog(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line no-console
    console.log(event.target.checked);
    setChecked(event.target.checked);
  };

  const loadMore = useCallback(async () => {
    const loadedProduct: any = await getProduct(id);
    const productImages = await Promise.all(
      loadedProduct?.imageKeys.map(async (imageKey) => ({
        imageUrl: await getUploadedImageUrl(imageKey),
      })),
    );
    setProduct({ ...loadedProduct, images: productImages });
    setTitle(loadedProduct.title);
    setDescription(loadedProduct.description);
    setCategory(loadedProduct.category);
    setSubcategory(loadedProduct.subcategory);
    setCurrentSubcategories(getSubcategories(loadedProduct.category)!);
    setPrice(loadedProduct.price);
    setQuality(loadedProduct.quality);
    setSize(loadedProduct?.size || null);
    setGender(loadedProduct?.gender || null);
    setMaterial(loadedProduct?.material || null);
    // eslint-disable-next-line no-console
    console.log('-----', loadedProduct.isInOffer);
    // eslint-disable-next-line no-unneeded-ternary
    setChecked(loadedProduct?.isInOffer === null ? false : true);

    const uploadedFiles: UploadFile[] = await Promise.all(
      loadedProduct?.imageKeys.map(async (imageKey) => ({
        key: imageKey,
        preview: await getUploadedImageUrl(imageKey),
        file: null,
      })),
    );
    setUploadFiles(uploadedFiles);
  }, [id]);

  useEffect(() => {
    loadMore();
  }, [loadMore]);

  async function sumbitHandler(event) {
    event.preventDefault();
    uploadFiles?.map(async (file) => {
      if (file.file) {
        await uploadImage(file.key, file.file!);
      }
    });

    const updateProduct: UpdateProductInputInput = {
      id,
      title,
      description,
      category,
      subcategory,
      email: tokenPayload?.email,
      price,
      imageKeys: uploadFiles?.map((item) => item.key),
      date: product.date,
      material: material || null,
      reservedFor: product.reservedFor,
      sold: false,
      quality: quality || null,
      size: size || null,
      gender: gender || null,
      isInOffer: checked || null,
    };

    try {
      await updateReserveProduct(updateProduct);
      setOpenSuccessDialog(true);
    } catch (e) {
      // console.error(e);
      setOpenErrorDialog(true);
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <EcoswapHeader />
      </div>
      <form onSubmit={sumbitHandler} className={classes.form}>
        <TextField
          id="title"
          label="Product name"
          value={title}
          onChange={handleTitleChange}
          className={classes.fullWidth}
          required
        />
        <TextField
          id="description"
          label="Product description"
          value={description}
          onChange={handleDescriptionChange}
          className={classes.fullWidth}
          multiline
          rows={4}
          required
        />
        <TextField
          id="category"
          label="Category"
          value={category}
          onChange={handleCategoryChange}
          select
          className={classes.fullWidth}
          required
        >
          {Categories.map((_category) => (
            <MenuItem key={_category} value={_category}>
              <ListItemIcon>
                <img
                  alt="category"
                  className={classes.icon}
                  src={`${process.env.PUBLIC_URL}/assets/categoryIcons/${_category.toLowerCase()}.png`}
                />
              </ListItemIcon>
              {_category}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="subcategory"
          label="Subcategory"
          value={subcategory}
          onChange={handleSubcategoryChange}
          select
          required
          className={classes.fullWidth}
        >
          {currentSubcategories.map((subcategory_name) => (
            <MenuItem key={subcategory_name} value={subcategory_name}>
              {subcategory_name}
            </MenuItem>
          ))}
        </TextField>
        {category === 'Clothes' && (
          <ClothesForm
            subCategory={subcategory}
            size={size}
            setSize={setSize}
            gender={gender}
            setGender={setGender}
            material={material}
            setMaterial={setMaterial}
          />
        )}
        <TextField
          id="quality"
          label="Condition"
          value={quality}
          onChange={handleQualityChange}
          select
          className={classes.fullWidth}
          required
        >
          {TypeOfQualities.map((quality_name) => (
            <MenuItem key={quality_name} value={quality_name}>
              {quality_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="price"
          label={`Price (Maximum is ${maxRange} points`}
          type="number"
          value={price}
          onChange={handlePriceChange}
          className={classes.fullWidth}
          InputProps={{ inputProps: { min: 1, max: maxRange } }}
          required
        />
        <h6 className={classes.fullWidth}> </h6>
        <Box className={classes.fullWidth}>
          <Typography variant="caption" display="block">
            Is in Offer
          </Typography>
          <Checkbox checked={checked} onChange={handleChange} />
        </Box>
        <h6 className={classes.fullWidth}>
          <b> * - Required field</b>
        </h6>

        <AddPhotos
          uploadFileHandler={(e) =>
            selectImageForUpload(e, setInfoAboutImage, uploadFiles, setUploadFiles, setOpenImagesDialog)
          }
          uploadFiles={uploadFiles}
          setUploadFiles={setUploadFiles}
          isProduct
          isUpdate
        />
      </form>
      <InformationDialog open={openSuccessDialog} onClose={handleSuccessDialogClose} title="Success">
        Product was successfully updated.
      </InformationDialog>
      <InformationDialog open={openErrorDialog} onClose={handleErrorDialogClose} title="Error">
        Product was not successfully updated. Please try again later.
      </InformationDialog>
      <InformationDialog open={openImagesDialog} onClose={handleImagesDialogClose} title="Success">
        {infoAboutImage}
      </InformationDialog>
    </div>
  );
};
