import {
  Box,
  Card,
  Container,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DatePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import {
  updateMyChallenge,
  fetchChallengeWithActivityTypes,
  addActivityType,
  fetchActivityType,
  deleteMyActivity,
  deleteMyChallenge,
} from '../../common/api/apiclient';
import { ActivityType, CreateActivityTypeInput, GetChallengeQuery, UpdateChallengeInput } from '../../API';
import ActivityTypeEditBox from '../../components/activities/ActivityTypeEditBox';
import { LoadingBox } from '../../components/LoadingBox/LoadingBox';
import { GetLocalDateTime } from '../../utils/TimeFormatter';

interface ParamTypes {
  id: string;
}
const useStyles = makeStyles(() =>
  createStyles({
    box: {
      padding: '2px',
      display: 'flex',
      color: 'lightblue',
    },
  }),
);

function GoGreenAdminEditPage() {
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams<ParamTypes>();
  const [challenge, setChallenge] = useState<GetChallengeQuery['getChallenge']>();
  const [activityTypeArray, setActivityTypeArray] = useState<ActivityType[]>([]);
  const [active, setActive] = useState(true);
  const [draft, setDraft] = useState(true);
  const [owner, setOwner] = useState('');
  const [editors, setEditors] = useState([]);
  const [tmpEditor, setTmpEditor] = useState('');
  const [deleteIsVisible, setDeleteIsVisible] = useState(false);

  const [challengeName, setChallengeName] = useState<string>('');
  const [challengeDescription, setChallengeDescription] = useState<string>('');

  const [apiTextResponse, setApiTextResponse] = useState<string>('Api Status board');

  const [selectedFromDate, handleFromDateChange] = useState<MaterialUiPickersDate>(GetLocalDateTime());
  const [selectedEndDate, handleEndDateChange] = useState<MaterialUiPickersDate>(GetLocalDateTime());

  const handleNameChange = ({ target }) => {
    setChallengeName(target.value);
  };

  const handleDescriptionChange = ({ target }) => {
    setChallengeDescription(target.value);
  };

  const [environment] = useState<'development' | 'production' | 'test'>(process.env.NODE_ENV);

  async function handleUpdateChallenge() {
    const updateChallengeInput: UpdateChallengeInput = {
      id: challenge!.id as string,
      active: String(active),
      draft: String(draft),
      description: challengeDescription as string,
      editors,
      endDate: typeof selectedEndDate === 'string' ? selectedEndDate : selectedEndDate?.toISODate(),
      name: challengeName as string,
      startDate: typeof selectedFromDate === 'string' ? selectedFromDate : selectedFromDate?.toISODate(),
    };

    try {
      const updatedChallenge = await updateMyChallenge(updateChallengeInput);
      setApiTextResponse(`Challenge updated with values: ${JSON.stringify(updatedChallenge)}`);
    } catch (e) {
      console.error(e);
      setApiTextResponse(`Unable to update challenge, error:${e}`);
    }
  }

  async function deleteAllActivities() {
    const allPromises: any = [];
    for (const item of activityTypeArray) {
      allPromises.push(await deleteActivity(item.id));
    }
    return Promise.all(allPromises);
  }

  async function handleDeleteChallenge() {
    await deleteAllActivities().then(async () => {
      try {
        await deleteMyChallenge({ id });
        history.push('/admin');
      } catch (e) {
        setApiTextResponse(`Failed to delete this challenge, error:${e}`);
      }
    });
  }

  async function handleAddEditor() {
    // @ts-ignore
    setEditors((editors) => [...editors, tmpEditor]);
    setTmpEditor('');
  }

  async function handleDeleteEditor() {
    const array = editors.filter((editor) => tmpEditor !== editor);
    setEditors(array);
    setTmpEditor('');
  }

  async function handleActivityCreate() {
    const activityType: CreateActivityTypeInput = {
      amountDescription: 'change me',
      challengeID: id,
      description: 'change me',
      name: 'change me',
      editors,
      order: 0,
      pointsPerAmount: 0,
      unit: 'change me',
      url: '',
    };

    try {
      const activity = await addActivityType(activityType);
      setActivityTypeArray((activityTypeArray) => [...activityTypeArray, activity]);
      setApiTextResponse(`Activity successfully created, id: ${activity.id}`);
    } catch (e) {
      console.error(e);
      setApiTextResponse(`Activity has not been created, error:${e}`);
    }
  }

  async function deleteActivity(id) {
    try {
      const resp = await deleteMyActivity({ id });

      const newArray = activityTypeArray.filter((item) => item.id !== id);
      setActivityTypeArray(newArray);
      setApiTextResponse(`ActivityType has been deleted, id: ${id}`);
      return resp;
    } catch (e) {
      console.error(e);
      setApiTextResponse(`ActivityType failed to delete, error: ${e}`);
    }
  }

  useEffect(() => {
    const loadChallenge = async () => {
      await fetchChallengeWithActivityTypes(id).then(async (challenge: any) => {
        setChallenge(challenge.getChallenge);
        setChallengeName(challenge.getChallenge.name);
        setChallengeDescription(challenge.getChallenge.description);
        setActive(challenge.getChallenge.active === 'true');
        setDraft(challenge.getChallenge.draft === 'true');
        handleFromDateChange(challenge.getChallenge.startDate);
        handleEndDateChange(challenge.getChallenge.endDate);
        setOwner(challenge.getChallenge.owner);
        challenge.getChallenge.editors ? setEditors(challenge.getChallenge.editors) : setEditors([]);

        for (const item of challenge.getChallenge.activityTypes.items) {
          const loadedActivityType = await fetchActivityType(item!.id);
          // @ts-ignore
          setActivityTypeArray((activityTypeArray) => [...activityTypeArray, loadedActivityType]);
        }
      });
    };

    loadChallenge();
  }, [id]);

  return (
    <div>
      <Container maxWidth="md">
        {challenge ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3 color="textSecondary">Edit challenge & Activities</h3>
              <div className="debug" style={{ overflowX: 'auto', height: '50px', whiteSpace: 'nowrap' }}>
                Owner / created by: {owner}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DatePicker
                minDate={new Date('2020-1-1')}
                maxDate={new Date('2080-1-1')}
                label="Challenge 'From' Date"
                value={selectedFromDate}
                onChange={handleFromDateChange}
                format="dd.LL.yyyy"
                inputVariant="outlined"
                disableToolbar
                variant="inline"
                autoOk
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DatePicker
                minDate={new Date('2020-1-1')}
                maxDate={new Date('2080-1-1')}
                label="Challenge 'End' Date"
                value={selectedEndDate}
                onChange={handleEndDateChange}
                format="dd.LL.yyyy"
                inputVariant="outlined"
                disableToolbar
                disablePast={false}
                variant="inline"
                autoOk
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControlLabel
                value={active}
                control={<Switch checked={active} />}
                label={active ? 'Active' : 'Old'}
                onChange={(e, c) => {
                  setActive(c);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControlLabel
                value={draft}
                control={<Switch checked={draft} />}
                label={draft ? 'Draft' : 'Published'}
                onChange={(e, c) => {
                  setDraft(c);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField label="Edit Title" variant="outlined" value={challengeName} onChange={handleNameChange} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Edit Description"
                variant="outlined"
                value={challengeDescription}
                onChange={handleDescriptionChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Button color="primary" variant="contained" fullWidth onClick={handleUpdateChallenge}>
                Update Challenge
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              {deleteIsVisible ? (
                <>
                  <Button color="secondary" variant="contained" onClick={handleDeleteChallenge}>
                    Yes (not reversible)
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setDeleteIsVisible(!deleteIsVisible);
                    }}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <Button
                  color="secondary"
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    setDeleteIsVisible(!deleteIsVisible);
                  }}
                >
                  Delete Challenge
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <div className="debug" style={{ overflowX: 'auto', height: '50px', whiteSpace: 'nowrap' }}>
                Editors: {editors != null ? editors.join(',') : 'none'}
              </div>
              <hr />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                label="Add Editor"
                variant="outlined"
                value={tmpEditor}
                fullWidth
                onChange={(event) => {
                  setTmpEditor(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Button color="default" variant="contained" fullWidth style={{ margin: '5px' }} onClick={handleAddEditor}>
                Add editor
              </Button>
            </Grid>
            <Grid item xs={12} sm={2} md={3}>
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                style={{ margin: '5px' }}
                onClick={handleDeleteEditor}
              >
                Delete editor
              </Button>
            </Grid>
            {environment === 'development' && (
              <Grid item xs={12}>
                <Card
                  className="debug"
                  variant="elevation"
                  style={{ backgroundColor: 'black', overflowX: 'auto', whiteSpace: 'nowrap' }}
                >
                  <Box className={classes.box}>{apiTextResponse}</Box>
                </Card>
              </Grid>
            )}
            <Grid item xs={12}>
              <hr />
              <h3>Edit activities</h3>
            </Grid>
            <Grid item xs={12}>
              {activityTypeArray ? (
                activityTypeArray!.map((activityType) => (
                  <ActivityTypeEditBox
                    key={activityType?.id}
                    loadedActivityType={activityType}
                    deleteCallback={deleteActivity}
                    setAPIStatusBoard={(text) => {
                      setApiTextResponse(text);
                    }}
                    editors={editors}
                  />
                ))
              ) : (
                <LoadingBox />
              )}
            </Grid>
            <Grid item xs={12}>
              <Button color="default" disabled={!active} variant="contained" onClick={handleActivityCreate}>
                Add activity
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="default"
                variant="contained"
                onClick={() => {
                  history.push('/admin');
                }}
              >
                Back to admin screen
              </Button>
            </Grid>
          </Grid>
        ) : (
          <LoadingBox />
        )}
      </Container>
    </div>
  );
}

export default GoGreenAdminEditPage;
