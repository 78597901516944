import React from 'react';
import { useLocation } from 'react-router-dom';
import EcoSwapNavigation from '../navigation/ecoswap/EcoSwapNavigation';
import GoGreenNavigation from '../navigation/gogreen/GoGreenNavigation';
import HomeNavigation from '../navigation/home/HomeNavigation';

export const Footer = () => {
  const location = useLocation();
  return (
    <footer>
      {
        {
          home: <HomeNavigation />,
          gogreen: <GoGreenNavigation />,
          ecoswap: <EcoSwapNavigation />,
        }[location.pathname.split('/')[1]]
      }
    </footer>
  );
};
