import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { LocalActivity } from '@material-ui/icons';
import { ChallengeByActiveAndStartDateQuery } from '../../API';
import {
  fetchActiveChallengesListByDate,
  fetchActivitiesList,
  getUploadedImagesUrls,
} from '../../common/api/apiclient';
import AddActivityButton from '../../components/activities/AddActivityButton';
import { ActivityBox } from '../../components/activities/ActivityBox';
import FetchActiveChallengesParams from '../../utils/FetchActiveChallengesParams';
import FetchActivitiesByVisibilityParams from '../../utils/FetchActivitiesByVisibilityParams';
import { GoGreenHeader } from '../../components/headers/GoGreenHeader';
import { FormatDateTime } from '../../utils/TimeFormatter';
import { findIcon } from '../../utils/IconMap';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'inline-flex',
      flexFlow: 'column',
      width: '100%',
      position: 'relative',
    },
    FontFamily1: {
      lineHeight: '18px',
      color: '#000000',
    },
    container: {
      padding: '0 25px',
      width: '100%',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    img: {
      maxHeight: '4.5em',
    },
  }),
);

export function ActivityFeedPage() {
  const classes = useStyles();
  const [challengeList, setChallengeList] = useState<
    ChallengeByActiveAndStartDateQuery['challengeByActiveAndStartDate']
  >();

  const [total, setTotal] = useState(0);
  const activities = useRef<any>([]);
  const loading = useRef(false);
  const [nextToken, setNextToken] = useState(null);

  const loadChallengeList = async () => {
    const fetchParams = new FetchActiveChallengesParams('true', 'DESC', 10, null);
    const loadedChallengeList = await fetchActiveChallengesListByDate(fetchParams);
    setChallengeList(loadedChallengeList);
  };

  const loadMore = useCallback(
    async (currentToken) => {
      if (loading.current || challengeList == null) {
        return;
      }

      loading.current = true;
      const fetchParams = new FetchActivitiesByVisibilityParams('feed', 'DESC', 20, currentToken);
      const loadedActivityList: any = await fetchActivitiesList(fetchParams);
      const filteredActivities = loadedActivityList.items.filter(
        (item) => item.activityType.challengeID === challengeList?.items[0]?.id,
      );

      if (loadedActivityList.items.length && challengeList.items.length) {
        const loadedActivityListWithAttachments = await Promise.all(
          filteredActivities.map(async (activity) => ({
            ...activity,
            attachmentUrls: activity.imageKeys ? await getUploadedImagesUrls(activity.imageKeys) : ['test'],
          })),
        );

        activities.current = [...activities.current, ...loadedActivityListWithAttachments];
      }

      setNextToken(loadedActivityList?.nextToken);
      setTotal(activities.current.length);
      loading.current = false;
    },
    [challengeList],
  );

  function generateItem(index) {
    return (
      <>
        {activities.current[index] && (
          <ActivityBox
            userName={activities.current[index].userName}
            activityType={activities.current[index].activityType.name}
            activityAmount={activities.current[index].amount}
            activityAmountUnit={activities.current[index].activityType.unit}
            activityPointsEarned={activities.current[index]?.points}
            activityDate={FormatDateTime(activities.current[index]?.activityDate)}
            attachmentUrls={activities.current[index].attachmentUrls}
          />
        )}
      </>
    );
  }

  useEffect(() => {
    loadChallengeList();
  }, []);

  useEffect(() => {
    loadMore(null);
  }, [loadMore]);

  return (
    <div className={classes.root}>
      <GoGreenHeader icon={LocalActivity} title="Activity Feed" />
      {challengeList?.items?.[0]?.id && (
        <div className={classes.container}>
          <Grid container spacing={3}>
            <Grid item>
              <img className={classes.img} src={findIcon(challengeList.items[0]?.name)} alt="Challenge Logo" />
            </Grid>
            <Grid item>
              <h5 className={classes.FontFamily1}>{challengeList.items[0]?.name}</h5>
              <h6 className={classes.FontFamily1} style={{ paddingBottom: '20px' }}>
                {challengeList.items[0]?.startDate} - {challengeList.items[0]?.endDate}
              </h6>
            </Grid>
          </Grid>
        </div>
      )}
      {activities.current && (
        <>
          <Virtuoso
            style={{ width: '100%', height: '57vh', marginBottom: '10px', marginTop: '10px' }}
            overscan={500}
            totalCount={total}
            item={generateItem}
            endReached={nextToken ? () => loadMore(nextToken) : undefined}
            footer={() => {
              return <>{nextToken && <div style={{ padding: '2rem', textAlign: 'center' }}>Loading...</div>}</>;
            }}
          />
          {challengeList?.items?.[0]?.id && <AddActivityButton challengeId={challengeList?.items[0]?.id} />}
        </>
      )}
    </div>
  );
}
