import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Button, Grid, TextField } from '@material-ui/core';
import { ActivityType, UpdateActivityTypeInput } from '../../API';
import { ChallengeBox } from '../ChallengeBox/ChallengeBox';
import { updateMyActivityType } from '../../common/api/apiclient';

const useStyles = makeStyles(() =>
  createStyles({
    margin: {
      margin: '5px',
    },
  }),
);

interface Props {
  loadedActivityType: ActivityType;
  deleteCallback: Function;
  setAPIStatusBoard: Function;
  editors: string[];
}

export default function ActivityTypeEditBox({ loadedActivityType, deleteCallback, setAPIStatusBoard, editors }: Props) {
  const classes = useStyles();
  const [id, setID] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [activityTypeName, setActivityTypeName] = useState('');
  const [activityTypePointsPerAmount, setActivityTypePointsPerAmount] = useState(0);
  const [activityTypeDescription, setActivityTypeDescription] = useState<string | null | undefined>('');
  const [activityTypeUrl, setActivityTypeUrl] = useState<string | null | undefined>('');
  const [activityTypeAmountDescription, setActivityTypeAmountDescription] = useState<string | null | undefined>('');
  const [activityTypeUnit, setActivityTypeUnit] = useState<string | null | undefined>('');
  const [activityTypeOrder, setActivityTypeOrder] = useState(0);
  const [activityTypeUrlDescription, setActivityTypeUrlDescription] = useState<string | null | undefined>('');

  useEffect(() => {
    setID(loadedActivityType.id);
    setActivityTypeName(loadedActivityType.name);
    setActivityTypePointsPerAmount(loadedActivityType!.pointsPerAmount);
    setActivityTypeDescription(loadedActivityType!.description);
    setActivityTypeUrl(loadedActivityType!.url);
    setActivityTypeAmountDescription(loadedActivityType!.amountDescription);
    setActivityTypeUnit(loadedActivityType!.unit);
    setActivityTypeOrder(loadedActivityType!.order);
    setActivityTypeUrlDescription(loadedActivityType.urlDescription);
  }, [id, loadedActivityType]);

  async function handleUpdateActivityType() {
    const activity: UpdateActivityTypeInput = {
      amountDescription: activityTypeAmountDescription,
      description: activityTypeDescription,
      id,
      name: activityTypeName,
      editors,
      urlDescription: activityTypeUrlDescription,
      order: Number(activityTypeOrder),
      pointsPerAmount: Number(activityTypePointsPerAmount),
      unit: activityTypeUnit,
      url: activityTypeUrl,
    };

    try {
      const updatedActivity = await updateMyActivityType(activity);
      setAPIStatusBoard(`Activity updated: ${updatedActivity.id}`);
    } catch (e) {
      console.error(e);
      setAPIStatusBoard(`Updating activity failed, id: ${activity.id}`);
    }
  }

  return (
    <>
      <ChallengeBox
        title={activityTypeName || ''}
        subtitle={`${activityTypeDescription}`}
        onClick={() => setIsVisible(!isVisible)}
      />
      {isVisible && (
        <Grid item xs={12} style={{ margin: '0 0 0 40px' }}>
          <h4 color="textSecondary">Editors: {editors != null ? editors.join(',') : 'none'}</h4>
          <TextField
            label="Edit Activity name"
            variant="outlined"
            className={classes.margin}
            value={activityTypeName}
            onChange={(event) => setActivityTypeName(event.target.value)}
          />
          <TextField
            label="Edit Points Per Amount"
            variant="outlined"
            className={classes.margin}
            value={activityTypePointsPerAmount}
            onChange={(event) => setActivityTypePointsPerAmount(+event.target.value)}
          />
          <TextField
            label="Edit Description"
            variant="outlined"
            className={classes.margin}
            value={activityTypeDescription}
            onChange={(event) => setActivityTypeDescription(event.target.value)}
          />
          <TextField
            label="Edit URL"
            variant="outlined"
            className={classes.margin}
            value={activityTypeUrl}
            onChange={(event) => setActivityTypeUrl(event.target.value)}
          />
          <TextField
            label="Edit URL Description"
            variant="outlined"
            className={classes.margin}
            value={activityTypeUrlDescription}
            onChange={(event) => setActivityTypeUrlDescription(event.target.value)}
          />
          <TextField
            label="Edit Amount Description"
            variant="outlined"
            className={classes.margin}
            value={activityTypeAmountDescription}
            onChange={(event) => setActivityTypeAmountDescription(event.target.value)}
          />
          <TextField
            label="Edit Unit"
            variant="outlined"
            className={classes.margin}
            value={activityTypeUnit}
            onChange={(event) => setActivityTypeUnit(event.target.value)}
          />
          <TextField
            label="Edit Order"
            variant="outlined"
            className={classes.margin}
            value={activityTypeOrder}
            onChange={(event) => setActivityTypeOrder(+event.target.value)}
          />
          <Button color="default" variant="contained" className={classes.margin} onClick={handleUpdateActivityType}>
            Update
          </Button>
          <Button
            color="default"
            variant="contained"
            className={classes.margin}
            onClick={() => deleteCallback(loadedActivityType.id)}
          >
            Delete
          </Button>
        </Grid>
      )}
    </>
  );
}
