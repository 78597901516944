import React, { ReactNode } from 'react';
import { makeStyles, createStyles, Paper, Theme, Grid } from '@material-ui/core';
import { findIcon } from '../../utils/IconMap';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: '10px auto',
      width: '80%',
      padding: '10px',
      background: '#ffffff',
      borderRadius: '11px',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      boxSizing: 'border-box',
      cursor: 'default',
    },
    icon: {
      width: '3.5vw',
      [theme.breakpoints.down('md')]: {
        width: '5vw',
      },
      [theme.breakpoints.down('sm')]: {
        width: '7.5vw',
      },
      [theme.breakpoints.down('xs')]: {
        width: '12vw',
      },
    },
    FontFamily1: {
      fontSize: '13px',
      lineHeight: '14.3px',
      color: '#7500c0',
    },
    FontFamily2: {
      fontWeight: 400,
      lineHeight: '15px',
    },
    FontFamily3: {
      fontSize: '13px',
      lineHeight: '14.3px',
    },
    active: {
      cursor: 'pointer',
      width: '90%',
    },
    center: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

interface Props {
  title: string;
  subtitle?: string;
  children?: ReactNode;
  isActive?: boolean;
  onClick?: () => void;
}

export const ChallengeBox = ({ title, subtitle, children, isActive, onClick }: Props) => {
  const classes = useStyles();
  const finalIcon = findIcon(title.trim());

  if (title === 'undefined is here!') {
    title = 'New challenge is coming!';
    subtitle = 'Stay tuned for more...';
  }

  return (
    <Paper className={`${classes.paper} ${isActive && classes.active}`} onClick={onClick}>
      <Grid container spacing={2}>
        <Grid item className={classes.center}>
          <img src={finalIcon} className={classes.icon} alt="Challenge logo" />
        </Grid>
        <Grid item xs container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item>
              <div style={{ height: 'auto' }}>
                <h4 className={isActive ? classes.FontFamily1 : classes.FontFamily3}>{title}</h4>
                {subtitle || (
                  <h6 color="textSecondary" className={classes.FontFamily2}>
                    {subtitle}
                  </h6>
                )}
                {children || (
                  <h6 color="textSecondary" className={classes.FontFamily2}>
                    {children}
                  </h6>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
