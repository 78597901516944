import React, { useEffect, useState, ReactNode } from 'react';
import { styled, BottomNavigation } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { BottomNavigationPaths } from './BottomNavigationPaths';

interface Props {
  children: ReactNode;
}
export const StyledBottomNavigation = ({ children }: Props) => {
  const location = useLocation();
  const [value, setValue] = useState(0);

  const handleChange = () => {
    setValue(-1);
  };

  useEffect(() => {
    const index = BottomNavigationPaths.filter((path) => path.url === location.pathname);
    setValue(index[0]?.value ?? -1);
  }, [location]);

  const StyledBottomNav = styled(BottomNavigation)({
    width: '100%',
    position: 'fixed',
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 -2px 13px 1px rgba(0, 0, 0, .14)',
    color: 'white',
    height: '10%',
    padding: '0 0px',
    zIndex: 3,
  });

  return (
    <StyledBottomNav className="bottom" value={value} onChange={handleChange} showLabels>
      {children}
    </StyledBottomNav>
  );
};
