import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { GetChallengeQuery } from '../../API';
import { findIcon } from '../../utils/IconMap';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'inline-flex',
      flexFlow: 'column',
      width: '100%',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
    },
    container: {
      padding: '0 25px',
      width: '100%',
    },
    challengeLogo: {
      maxHeight: '4.5em',
    },
    border: {
      border: '2px solid rgba(0, 0, 0, 0.12)',
      borderStyle: 'solid none',
      marginBottom: '15px',
      padding: '10px 0',
      marginTop: '1.5em',
      display: 'flex',
      alignItems: 'center',
    },
    container_text: {
      marginLeft: '1.3em',
    },
    title: {
      padding: '5px 0',
      fontSize: '25px',
      color: '#7500c0',
    },
  }),
);

interface ChallengeHeaderProps {
  challenge: GetChallengeQuery['getChallenge'];
  title: string;
  text: string;
}

export const ChallengeHeader = ({ challenge, title, text }: ChallengeHeaderProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <h5 className={classes.title}>{title}</h5>
        <h4>{text}</h4>
        <div className={classes.border}>
          <img className={classes.challengeLogo} src={findIcon(title)} alt="Challenge logo" />
          <div className={classes.container_text}>
            <h5>{challenge?.name}</h5>
            <h6>
              {challenge?.startDate} - {challenge?.endDate}
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};
