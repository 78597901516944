import React from 'react';
import Amplify from 'aws-amplify';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider, createTheme, makeStyles, createStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import './App.css';
import awsconfig from './aws-exports';
import { Footer } from './components/Footer/Footer';

import { GoGreenAdminPage } from './pages/admin/GoGreenAdminPage';
import GoGreenAdminEditPage from './pages/admin/GoGreenAdminEditPage';
import ChallengeCreatePage from './pages/admin/ChallengeCreatePage';

import { LeaderboardPage } from './pages/gogreen/LeaderboardPage';
import ChallengeActivityTypesPage from './pages/gogreen/ChallengeActivityTypesPage';
import { NewActivityPage } from './pages/gogreen/NewActivityPage';
import { GoGreenHomePage } from './pages/gogreen/GoGreenHomePage';
import { ActivityFeedPage } from './pages/gogreen/ActivityFeedPage';

import { ProductPage } from './pages/ecoswap/ProductPage';
import { EcoSwapHomePage } from './pages/ecoswap/EcoSwapHomePage';
import { ProductsPage } from './pages/ecoswap/ProductsPage';
import { CategoryPage } from './pages/ecoswap/CategoryPage';
import { AddProductPage } from './pages/ecoswap/AddProductPage';
import { EditProduct } from './pages/ecoswap/EditProduct';

import { Profile } from './pages/home/Profile';
import { Terms } from './pages/home/Terms';
import { ContactUs } from './pages/home/ContactUs';
import { Home } from './pages/home/Home';

Amplify.configure(awsconfig);

const useStyles = makeStyles(() =>
  createStyles({
    body: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
  }),
);

const theme = createTheme({
  palette: {
    primary: {
      main: '#7500C0',
    },
  },
});

export const App = () => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <Router>
          <main className={classes.body}>
            <Switch>
              {/* Admin Paths */}
              <Route path="/admin" exact>
                <GoGreenAdminPage />
              </Route>
              <Route path="/admin/create/challenge">
                <ChallengeCreatePage />
              </Route>
              <Route path="/admin/edit/challenges/:id">
                <GoGreenAdminEditPage />
              </Route>
              {/* GoGreen Paths */}
              <Route path="/gogreen/activityTypes/:id/newActivity">
                <NewActivityPage />
              </Route>
              <Route path="/gogreen/challenges" exact>
                <LeaderboardPage />
              </Route>
              <Route path="/gogreen/challenges/:id/activityTypes">
                <ChallengeActivityTypesPage />
              </Route>
              <Route path="/gogreen/home">
                <GoGreenHomePage />
              </Route>
              <Route path="/gogreen/activityFeed">
                <ActivityFeedPage />
              </Route>
              <Route path="/gogreen/profile">
                <Profile />
              </Route>
              <Route path="/gogreen/terms">
                <Terms />
              </Route>
              {/* EcoSwap Paths */}
              <Route exact path="/ecoswap">
                <EcoSwapHomePage />
              </Route>
              <Route exact path="/ecoswap/profile">
                <Profile />
              </Route>
              <Route path="/ecoswap/terms">
                <Terms />
              </Route>
              {/* Add product */}
              <Route exact path="/ecoswap/addproduct">
                <AddProductPage />
              </Route>
              {/* Get product */}
              <Route exact path="/ecoswap/category">
                <CategoryPage />
              </Route>
              <Route path="/ecoswap/products" exact>
                <ProductsPage />
              </Route>
              <Route path="/ecoswap/products/:id" exact>
                <ProductPage />
              </Route>
              <Route path="/ecoswap/products/edit/:id">
                <EditProduct />
              </Route>
              {/* Home Page Path */}
              <Route path="/home" exact>
                <Home />
              </Route>
              <Route path="/home/profile">
                <Profile />
              </Route>
              <Route path="/home/terms">
                <Terms />
              </Route>
              <Route path="/home/contactUs">
                <ContactUs />
              </Route>
              <Route path="/">
                <Redirect to="/home" />
              </Route>
            </Switch>
          </main>
          <Footer />
        </Router>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};
