import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import { DatePicker } from '@material-ui/pickers';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { fetchActivityType, addActivity, uploadImage } from '../../common/api/apiclient';
import { CreateActivityInput, GetActivityTypeQuery } from '../../API';
import { useAuthContext } from '../../common/context/AuthContext';
import { selectImageForUpload, UploadFile } from '../../components/products/AddProductHelpers';
import { LoadingBox } from '../../components/LoadingBox/LoadingBox';
import AddPhotos from '../../components/photos/AddPhotos';
import InformationDialog from '../../components/dialogs/InformationDialog';
import { GetLocalDateTime } from '../../utils/TimeFormatter';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: '0 25px',
      width: '100%',
    },
    FontFamily2: {
      color: '#7500c0',
      marginBottom: '25px',
      fontWeight: 'bold',
      fontSize: '13px',
    },
    halfWidth: {
      width: '50%',
      fontWeight: 'bold',
    },
    center: {
      justifyContent: 'center',
    },
    formContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      textAlign: 'center',
    },
    fullWidth: {
      width: '80%',
      margin: '0 10%',
      marginTop: '10px',
    },
    title: {
      marginTop: '15px',
    },
  }),
);

interface Params {
  id: string;
}

export function NewActivityPage() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<Params>();
  const { user } = useAuthContext();
  const tokenPayload = user?.getSignInUserSession()?.getIdToken().payload;

  const [activityUnit, setActivityUnit] = useState<string>('');
  const [activityType, setActivityType] = useState<GetActivityTypeQuery['getActivityType']>(null);
  const [selectedDate, handleDateChange] = useState<MaterialUiPickersDate>(GetLocalDateTime());
  const [distance, setDistance] = useState<string>('');
  const [uploadFiles, setUploadFiles] = useState<UploadFile[] | null>([]);

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openImagesDialog, setOpenImagesDialog] = useState(false);
  const [infoAboutImage, setInfoAboutImage] = useState('');

  const handleSuccessDialogClose = () => {
    setOpenSuccessDialog(false);
    history.push('/gogreen/home');
  };

  const handleErrorDialogClose = () => {
    setOpenErrorDialog(false);
  };

  const handleImagesDialogClose = () => {
    setOpenImagesDialog(false);
  };

  useEffect(() => {
    const loadActivityType = async () => {
      const loadedActivityType = await fetchActivityType(id);
      setActivityType(loadedActivityType);
      const tmpUnit = loadedActivityType?.unit?.replace(/[0-9]/g, '') as string;
      setActivityUnit(tmpUnit);
    };
    loadActivityType();
  }, [id]);

  const handleDistanceChange = ({ target }) => {
    let numberValue = +target.value;
    if (numberValue <= 0) {
      setDistance('');
      return;
    }
    if (numberValue > 200) {
      numberValue = 200;
    }
    setDistance(`${numberValue}`);
  };

  async function addActivityOfType(event) {
    event.preventDefault();
    if (selectedDate) {
      uploadFiles?.map(async (file) => {
        await uploadImage(file.key, file.file!);
      });

      const inputActivity: CreateActivityInput = {
        activityTypeId: id,
        email: tokenPayload?.email,
        userName: `${tokenPayload?.given_name} ${tokenPayload?.family_name}`,
        amount: +(distance || 0),
        points: +(distance || 0) * (activityType?.pointsPerAmount || 0),
        activityDate: selectedDate?.toUTC()?.toISO() as string,
        visibility: 'feed',
        imageKeys: uploadFiles?.map((item) => item.key),
      };
      try {
        await addActivity(inputActivity);
        setOpenSuccessDialog(true);
      } catch (e) {
        setErrorMessage('There was an error when adding the activity. Please try again.');
        setOpenErrorDialog(true);
      }
    } else {
      setErrorMessage('No date was selected for the activity.');
      setOpenErrorDialog(true);
    }
  }

  return (
    <div className={classes.container}>
      {activityType ? (
        <>
          <h4 className={classes.title}>Create Activity</h4>
          <h6 color="textSecondary" className={classes.FontFamily2}>
            {activityType.name} - {activityType.description}
            {activityType.url && (
              <a href={activityType.url} target="_blank" rel="noopener noreferrer">
                &nbsp; | {activityType.urlDescription}
              </a>
            )}
          </h6>
          <form onSubmit={addActivityOfType}>
            <DatePicker
              minDate={new Date(activityType?.challenge?.startDate!)}
              maxDate={new Date(activityType?.challenge?.endDate!)}
              className={classes.fullWidth}
              label="Activity Date"
              value={selectedDate}
              onChange={handleDateChange}
              format="dd.LL.yyyy"
              disableFuture
              inputVariant="outlined"
              disableToolbar
              variant="inline"
              autoOk
            />
            <TextField
              id="outlined-basic"
              label={
                distance
                  ? `${activityType.amountDescription} - ${activityType.pointsPerAmount * +distance} Points`
                  : activityType.amountDescription
              }
              variant="outlined"
              className={classes.fullWidth}
              value={distance}
              type="number"
              onChange={handleDistanceChange}
              InputProps={{
                endAdornment: <InputAdornment position="end">{activityUnit}</InputAdornment>,
              }}
              required
            />
            <AddPhotos
              uploadFileHandler={(e) =>
                selectImageForUpload(e, setInfoAboutImage, uploadFiles, setUploadFiles, setOpenImagesDialog)
              }
              uploadFiles={uploadFiles}
              setUploadFiles={setUploadFiles}
              isProduct={false}
              isUpdate={false}
            />
          </form>
        </>
      ) : (
        <LoadingBox />
      )}
      <InformationDialog open={openSuccessDialog} onClose={handleSuccessDialogClose} title="Success">
        Acitivity was successfully added
      </InformationDialog>
      <InformationDialog open={openErrorDialog} onClose={handleErrorDialogClose} title="Error">
        {errorMessage}
      </InformationDialog>
      <InformationDialog open={openImagesDialog} onClose={handleImagesDialogClose} title="Success">
        {infoAboutImage}
      </InformationDialog>
    </div>
  );
}
