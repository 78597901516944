import React from 'react';
import { createStyles, makeStyles, Theme, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import GenericCategoryBackground from '../../icons/backgrounds/categories/generalCategory.png';
import { SearchBar } from '../SearchBar/SearchBar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      zIndex: 3,
      position: 'relative',
    },
    img: {
      objectFit: 'fill',
      width: '100%',
    },
    searchWrapper: {
      position: 'absolute',
      top: '25%',
      left: '20%',
      width: '80%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.up('xl')]: {
        top: '35%',
      },
    },
    button: {
      color: 'white',
      float: 'right',
      marginRight: '2.5vw',
      backgroundColor: '#7500C0',
      borderRadius: '1em',
      fontSize: '1em',
      height: '5vh',
      background: 'linear-gradient(146deg, rgba(0,255,119,1) 10%, rgba(117,0,192,1) 45%)',
      '&:hover': {
        backgroundColor: '#34e218',
        boxShadow: '0px 0px 15px 5px #7500C0',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: '1em',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '1em',
      },
    },
  }),
);

export default function ProductHeader() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <img src={GenericCategoryBackground} className={classes.img} alt="Category background" />
      <div className={classes.searchWrapper}>
        <SearchBar />
        <Button
          color="default"
          variant="contained"
          className={classes.button}
          onClick={() => history.push('/ecoswap/addproduct')}
        >
          Add Product
        </Button>
      </div>
    </div>
  );
}
