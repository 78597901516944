import React, { useRef, useState, SyntheticEvent } from 'react';
import { Button, TextField, Snackbar, IconButton, Box } from '@material-ui/core';
import { useFormControls } from '../../components/forms/ContactFormControls';

export const ContactUs = () => {
  const inputFieldValues = [
    {
      name: 'name',
      label: 'Name',
      id: 'name',
      ref: useRef<HTMLInputElement>(null),
    },
    {
      name: 'email',
      label: 'Email',
      id: 'email',
      ref: useRef<HTMLInputElement>(null),
    },
    {
      name: 'message',
      label: 'Message',
      id: 'message',
      ref: useRef<HTMLInputElement>(null),
      multiline: true,
      minRows: 10,
    },
  ];
  const { handleInputValue, handleFormSubmit, formIsValid, errors } = useFormControls();
  const [open, setOpen] = useState(false);

  const handleSubmit = () => {
    setOpen(true);
    inputFieldValues.forEach((inputFieldValue) => {
      inputFieldValue.ref.current!.value = '';
    });
  };

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} />
    </>
  );

  return (
    <>
      <form autoComplete="off" onSubmit={handleFormSubmit}>
        <Box sx={{ m: 2 }}>
          <h3 style={{ paddingTop: '20px' }}>Contact Us</h3>
          {inputFieldValues.map((inputFieldValue) => {
            return (
              <TextField
                key={inputFieldValue.name}
                variant="outlined"
                inputRef={inputFieldValue.ref}
                onChange={handleInputValue}
                onBlur={handleInputValue}
                name={inputFieldValue.name}
                label={inputFieldValue.label}
                error={false}
                style={{ margin: '5px' }}
                multiline={inputFieldValue.multiline ?? false}
                fullWidth
                minRows={inputFieldValue.minRows ?? 1}
                autoComplete="none"
                {...(errors[inputFieldValue.name] && {
                  error: true,
                  helperText: errors[inputFieldValue.name],
                })}
              />
            );
          })}
          <Button
            variant="contained"
            type="submit"
            color="secondary"
            onClick={handleSubmit}
            disabled={!formIsValid()}
            style={{ float: 'right' }}
          >
            Send Message
          </Button>
        </Box>
      </form>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={1000}
        onClose={handleClose}
        message="Form is submitted"
        action={action}
      />
    </>
  );
};
