import { createStyles, Grid, makeStyles, Theme, Box, Tabs, Tab, FormControlLabel, Checkbox } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { Challenge, ChallengeByActiveAndStartDateQuery } from '../../API';
import Product from '../../components/products/Product';
import {
  fetchActivitiesListByDate,
  getUserProfileByEmail,
  getUploadedImagesUrls,
  getUploadedImageUrl,
  fetchChallengesList,
  getProducts,
  fetchChallengeLeaderboardEntries,
} from '../../common/api/apiclient';
import { useAuthContext } from '../../common/context/AuthContext';
import AddActivityButton from '../../components/activities/AddActivityButton';
import { ActivityBox } from '../../components/activities/ActivityBox';
import { ChallengeBox } from '../../components/ChallengeBox/ChallengeBox';
import FetchActiveChallengesParams from '../../utils/FetchActiveChallengesParams';
import FetchActivitiesByDateParams from '../../utils/FetchActivitiesByDateParams';
import { TabPanel } from '../../components/TabPanel/TabPanel';
import EcoswapIcon from '../../icons/apps/ecoswap.png';
import GogreenIcon from '../../icons/apps/challanges.png';
import { LoadingBox } from '../../components/LoadingBox/LoadingBox';
import { GoGreenHeader } from '../../components/headers/GoGreenHeader';
import { FormatDateTime } from '../../utils/TimeFormatter';
import { findIcon } from '../../utils/IconMap';
import FetchLeaderboardParams from '../../utils/FetchLeaderboardParams';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-flex',
      flexFlow: 'column',
      width: '100%',
    },
    partialWidth: {
      width: '80%',
      margin: '0 10%',
    },
    FontFamily1: {
      lineHeight: '18px',
      color: '#000000',
    },
    img: {
      maxHeight: '4em',
    },
    points: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '50px',
    },
    pointsLabel: {
      margin: '0',
      padding: '0',
      marginLeft: '30px',
      fontSize: '25px',
    },
    icon: {
      width: '4.5em',
    },
    item: {
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
    },
    rewardsGrid: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '70vh',
    },
    marginBottom: {
      [theme.breakpoints.down('xs')]: {
        marginBottom: '3em',
      },
    },
    border: {
      border: '2px solid rgba(0, 0, 0, 0.12)',
      borderStyle: 'solid none',
      marginBottom: '10px',
      display: 'flex',
      alignItems: 'center',
    },
    pastChallangesBox: {
      width: '100%',
      paddingBottom: '70px',
    },
  }),
);

const userInfoTabs = ['My Products', 'Swaps', 'Challenges', 'Rewards'];

export function Profile() {
  const classes = useStyles();
  const { user } = useAuthContext();
  const userMail = user?.getUsername();

  const [total, setTotal] = useState(0);
  const [products, setProducts] = useState<any>();

  const [activeChallenge, setActiveChallenge] = useState<Challenge>();
  const [nextToken, setNextToken] = useState(null);

  const [ecoSwapPoints, setEcoSwapPoints] = useState(0);
  const [challangePoints, setChallangePoints] = useState(0);

  const [value, setValue] = useState(0);
  const [isReserved, setIsReserved] = useState<boolean>(false);
  const [challengeListHistory, setChallengeListHistory] = useState<
    ChallengeByActiveAndStartDateQuery['challengeByActiveAndStartDate']
  >();
  const items = useRef<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getTokenPayload = useCallback(() => {
    return user?.getSignInUserSession()?.getIdToken().payload;
  }, [user]);

  const handleFilterChange = ({ target }) => {
    setIsReserved(target.checked);
  };

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const loadProducts = useCallback(async () => {
    const loadedProducts = await getProducts();
    const loadedProductsWithAttachments = await Promise.all(
      loadedProducts.items.map(async (product) => ({
        ...product,
        attachmentUrls: await getUploadedImageUrl(product.imageKeys[0]),
      })),
    );
    setProducts(loadedProductsWithAttachments);
  }, []);

  const loadActivities = useCallback(
    async (currentToken) => {
      const fetchParams = new FetchActivitiesByDateParams(getTokenPayload()?.email, 'DESC', 20, currentToken);
      const loadedActivityList = await fetchActivitiesListByDate(fetchParams);
      if (loadedActivityList?.items?.length) {
        const loadedActivityListWithAttachments = await Promise.all(
          loadedActivityList.items.map(async (activity) => ({
            ...activity,
            attachmentUrls: activity.imageKeys ? await getUploadedImagesUrls(activity.imageKeys) : [],
          })),
        );
        items.current = [...items.current, ...loadedActivityListWithAttachments];
      }
      setNextToken(loadedActivityList.nextToken);
      setTotal(items.current.length);
    },
    [getTokenPayload],
  );

  const loadMore = useCallback(
    async (currentToken) => {
      if (!user) return;

      setIsLoading(true);
      const profile = await getUserProfileByEmail(getTokenPayload()?.email);
      setEcoSwapPoints(profile.balance);

      loadProducts();
      loadActivities(currentToken);
      setIsLoading(false);
    },
    [user, getTokenPayload, loadProducts, loadActivities],
  );

  useEffect(() => {
    loadMore(null);
  }, [loadMore]);

  const loadPoints = useCallback(async () => {
    if (!activeChallenge?.id) return;

    const fetchParams = new FetchLeaderboardParams(activeChallenge?.id, 'DESC', 20, null);
    const loadedUsersList = await fetchChallengeLeaderboardEntries(fetchParams);
    setChallangePoints(
      loadedUsersList?.leaderboardEntryByChallengeAndPoints.items.find(({ owner }) => owner === userMail).totalPoints,
    );
  }, [activeChallenge, userMail]);

  useEffect(() => {
    loadPoints();
  }, [loadPoints]);

  const loadChallengeList = useCallback(async () => {
    const fetchParams = new FetchActiveChallengesParams('true', 'DESC', 10, null);
    const loadedChallengeList = await fetchChallengesList(fetchParams);
    setChallengeListHistory(loadedChallengeList);
    const activeChallanges = loadedChallengeList?.items.filter((challange) => {
      return challange!.active === 'true' && challange!.draft === 'false';
    });
    if (activeChallanges) {
      setActiveChallenge(activeChallanges[0] as Challenge);
    }
  }, []);

  useEffect(() => {
    loadChallengeList();
  }, [loadChallengeList]);

  const generateItem = (index) => {
    return (
      <>
        {items.current[index] && (
          <ActivityBox
            userName={`${getTokenPayload()?.given_name} ${getTokenPayload()?.family_name}`}
            activityType={items.current[index].activityType.name}
            activityAmount={items.current[index].amount}
            activityAmountUnit={items.current[index].activityType.unit}
            activityPointsEarned={items.current[index]?.points}
            activityDate={FormatDateTime(items.current[index]?.activityDate)}
            attachmentUrls={items.current[index].attachmentUrls}
          />
        )}
      </>
    );
  };

  return (
    <div className={classes.root}>
      <GoGreenHeader icon={Person} title={`${getTokenPayload()?.given_name} ${getTokenPayload()?.family_name}`} />
      <Grid container spacing={3} className={classes.partialWidth}>
        <Grid xs={12} item>
          <Box display="flex" justifyContent="center">
            <h3 style={{ justifyContent: 'center' }}>
              You have <b>{ecoSwapPoints}</b> points
            </h3>
          </Box>
        </Grid>
      </Grid>
      <Grid xs={12} item>
        <Box display="flex" justifyContent="center">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{ style: { background: '#7500c0' } }}
          >
            {userInfoTabs.map((tab, index) => (
              <Tab label={tab} id={`simple-tab-${index}`} aria-controls={`simple-tabpanel-${index}`} key={tab} />
            ))}
          </Tabs>
        </Box>
      </Grid>
      <TabPanel value={value} index={0}>
        <Grid xs={12} item style={{ marginTop: '5px' }}>
          <FormControlLabel
            control={<Checkbox checked={isReserved} onChange={handleFilterChange} />}
            label="Reserved products"
          />
        </Grid>
        <Grid container spacing={4} className={classes.marginBottom}>
          {isLoading && <LoadingBox />}
          {products &&
            products
              .filter(
                (product) => !product.sold && product.owner === userMail && (isReserved ? product.reservedFor : true),
              )
              .map((product) => (
                <Grid xl={2} lg={3} md={4} sm={6} xs={12} item key={product.id} className={classes.item}>
                  <Product
                    title={product.title}
                    urls={product.attachmentUrls}
                    key={product.id}
                    id={product.id}
                    reservedFor={product.reservedFor}
                    owner={product.owner}
                    sold={product.sold}
                    price={product.price}
                  />
                </Grid>
              ))}
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Grid container spacing={4} className={classes.marginBottom}>
          {products &&
            products
              .filter((product) =>
                product.sold
                  ? product.email === userMail!.split('_')[1] || product.owner === userMail!.split('_')[1]
                  : product.reservedFor === getTokenPayload()?.email,
              )
              .map((product) => (
                <Grid xl={2} lg={3} md={4} sm={6} xs={12} item key={product.id} className={classes.item}>
                  <Product
                    title={product.title}
                    urls={product.attachmentUrls}
                    key={product.id}
                    id={product.id}
                    reservedFor={product.reservedFor}
                    owner={product.owner}
                    sold={product.sold}
                    price={product.price}
                  />
                </Grid>
              ))}
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <h3 style={{ paddingTop: '25px' }}>Challenges</h3>
        <Grid container style={{ padding: '20px 10px 0px' }}>
          <Grid item xs={12} style={{ position: 'relative' }}>
            {activeChallenge && (
              <>
                <Grid container spacing={3} className={classes.border}>
                  <Grid item>
                    <img className={classes.img} src={findIcon(activeChallenge?.name)} alt="Challenge logo" />
                  </Grid>
                  {activeChallenge?.id && (
                    <Grid item>
                      <h5 className={classes.FontFamily1}>{activeChallenge?.name}</h5>
                      <h6 className={classes.FontFamily1} style={{ paddingBottom: '15px' }}>
                        {activeChallenge?.startDate}&nbsp; - &nbsp;{activeChallenge?.endDate}
                      </h6>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
            {user && (
              <>
                <Virtuoso
                  style={{ width: '100%', height: '40vh' }}
                  overscan={500}
                  totalCount={total}
                  item={generateItem}
                  endReached={nextToken ? () => loadMore(nextToken) : undefined}
                  footer={() => {
                    return <>{nextToken && <div style={{ padding: '2rem', textAlign: 'center' }}>Loading...</div>}</>;
                  }}
                />
                {activeChallenge?.id && (
                  <>
                    <AddActivityButton challengeId={activeChallenge?.id} />
                  </>
                )}
              </>
            )}
          </Grid>
          <Grid className={classes.pastChallangesBox}>
            <h5 style={{ paddingTop: '15px' }}>Past Challenges</h5>
            {challengeListHistory?.items ? (
              <>
                {challengeListHistory.items
                  .slice(0)
                  .reverse()
                  .map(
                    (challenge) =>
                      challenge?.active === 'false' && (
                        <Grid item key={challenge?.id}>
                          <ChallengeBox title={challenge.name}>
                            {challenge.startDate as string} - {challenge.endDate}
                          </ChallengeBox>
                        </Grid>
                      ),
                  )}
              </>
            ) : (
              <LoadingBox />
            )}
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Grid xs={12} item className={classes.rewardsGrid}>
          <div className={classes.points}>
            <img src={EcoswapIcon} className={classes.icon} alt="Ecoswap" />
            <p className={classes.pointsLabel}>{ecoSwapPoints} Ecoswap points</p>
          </div>
          <div className={classes.points}>
            <img src={GogreenIcon} className={classes.icon} alt="Ecoswap" />
            <p className={classes.pointsLabel}>{challangePoints} Challange points</p>
          </div>
        </Grid>
      </TabPanel>
    </div>
  );
}
