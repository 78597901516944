import { ChangeEvent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IMAGE_SIZE } from '../../config';

export type UploadFile = {
  key: string;
  preview: string;
  file: File | null;
};

// read file, create preview and put them into data structures
const ProcessFile = (
  inputFile: FileList,
  uploadFiles: UploadFile[] | null,
  setUploadFiles: Function,
  setOpenImagesDialog: Function,
) => {
  const newUploadFiles = uploadFiles!.slice();

  for (let index = 0; index < inputFile.length; index++) {
    const file = inputFile.item(index);

    const newFile: UploadFile = {
      key: uuidv4(),
      file,
      preview: '',
    };

    const reader = new FileReader();
    reader.onloadend = () => {
      newFile.preview = reader.result as string;
      if (uploadFiles) {
        newUploadFiles.push(newFile);
      }
    };
    reader.readAsDataURL(file!);
  }
  setUploadFiles(newUploadFiles);
  setOpenImagesDialog(true);
};

export async function selectImageForUpload(
  event: ChangeEvent<HTMLInputElement>,
  setInfoAboutImage: Function,
  uploadFiles: UploadFile[] | null,
  setUploadFiles: Function,
  setOpenImagesDialog: Function,
) {
  const { files } = event.target;

  if (files) {
    const file = files?.item(0);
    if (file?.size && file.size > IMAGE_SIZE) {
      setInfoAboutImage('Image is too big');
    } else if (file?.type === 'image/png' || file?.type === 'image/jpeg') {
      ProcessFile(files, uploadFiles, setUploadFiles, setOpenImagesDialog);
      setInfoAboutImage(`${files.length > 1 ? 'Images have' : 'Image has'} been added`);
    } else {
      setInfoAboutImage('Bad format of picture');
    }
  }
}

export async function removeFileFromUpload(key: string, uploadFiles: UploadFile[] | null, setUploadFiles: Function) {
  if (uploadFiles) {
    const newFiles = uploadFiles.filter((file) => file.key !== key);
    setUploadFiles(newFiles);
  }
}
