import { BottomNavigationAction, CssBaseline, Theme } from '@material-ui/core';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import AccountCircleSharpIcon from '@material-ui/icons//AccountCircleSharp';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import IconPack from '../../../utils/IconPack';
import { GoGreenNavLinks } from './GoGreenNavLinks';
import { StyledBottomNavigation } from '../StyledBottomNavigation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: '0.875rem !important',
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.6rem !important',
      },
    },
  }),
);

const GoGreenNavigation = () => {
  const classes = useStyles();
  return (
    <CssBaseline>
      <StyledBottomNavigation>
        <BottomNavigationAction
          label={<span className={classes.label}>Home</span>}
          classes={{ selected: 'SelectedBottom' }}
          icon={<IconPack.HomeIcon />}
          component={Link}
          to={GoGreenNavLinks[0]}
        />
        <BottomNavigationAction
          label={<span className={classes.label}>Challenges</span>}
          classes={{ selected: 'SelectedBottom' }}
          icon={<IconPack.LeafRightIcon />}
          component={Link}
          to={GoGreenNavLinks[1]}
        />
        <BottomNavigationAction
          label={<span className={classes.label}>Profile</span>}
          className="SelectedBottom"
          icon={<AccountCircleSharpIcon />}
          component={Link}
          to={GoGreenNavLinks[2]}
        />
        <BottomNavigationAction
          label={<span className={classes.label}>Feed</span>}
          className="SelectedBottom"
          icon={<IconPack.SharedFeedIcon />}
          component={Link}
          to={GoGreenNavLinks[3]}
        />
        <BottomNavigationAction
          label={<span className={classes.label}>Leaderboard</span>}
          className="SelectedBottom"
          icon={<IconPack.ChallengesIcon />}
          component={Link}
          to={GoGreenNavLinks[4]}
        />
      </StyledBottomNavigation>
    </CssBaseline>
  );
};

export default withRouter(GoGreenNavigation);
