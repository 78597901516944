import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Fab, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import FetchListParams from '../../utils/FetchListParams';
import { fetchChallengesList } from '../../common/api/apiclient';
import { ListChallengesQuery } from '../../API';
import { ChallengeBox } from '../../components/ChallengeBox/ChallengeBox';
import { LoadingBox } from '../../components/LoadingBox/LoadingBox';

export function GoGreenAdminPage() {
  const [challengeList, setChallengeList] = useState<ListChallengesQuery['listChallenges']>();

  const loadChallengeList = async () => {
    const filter = {
      id: { contains: '' },
    };
    const nextToken = null;
    const fetchParams = new FetchListParams(filter, 20, nextToken);
    const loadedChallengeList = await fetchChallengesList(fetchParams);
    setChallengeList(loadedChallengeList);
  };

  useEffect(() => {
    loadChallengeList();
  }, []);

  return (
    <div>
      <Container maxWidth="md">
        <Grid container>
          <Grid item xs={12}>
            <h3 style={{ paddingTop: '10px' }}>Edit Challenges</h3>
          </Grid>
          {challengeList?.items ? (
            <>
              <Grid item xs={12}>
                {challengeList?.items?.map((challenge) => (
                  <ChallengeBox key={challenge!.id} title={challenge!.name} onClick={() => null}>
                    {challenge!.startDate as string} - {challenge!.endDate}
                    <Link
                      to={`/admin/edit/challenges/${challenge?.id}`}
                      style={{ color: '#7500C0', fontWeight: 'bold' }}
                    >
                      {} EDIT {'>>'}
                    </Link>
                    {challenge!.active === 'true' ? ' Active' : ' Past challenge'}
                  </ChallengeBox>
                ))}
                <Fab
                  style={{ marginLeft: '50px' }}
                  color="primary"
                  aria-label="add"
                  variant="extended"
                  component={Link}
                  to="/admin/create/challenge"
                >
                  <AddIcon />
                </Fab>
              </Grid>
            </>
          ) : (
            <LoadingBox />
          )}
        </Grid>
      </Container>
    </div>
  );
}
