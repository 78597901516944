/* src/App.js */
import { API, Auth, graphqlOperation, Storage } from 'aws-amplify';
import {
  createActivity,
  createActivityType,
  createChallenge,
  createProductInput,
  createProfile,
  createTransaction,
  deleteActivityType,
  deleteChallenge,
  updateActivityType,
  updateChallenge,
  updateProductInput,
  updateProfile,
  deleteProductInput,
} from '../../graphql/mutations';
import {
  activityByEmailAndActivityDate,
  activityByVisibilityAndCreatedAt,
  challengeByActiveAndStartDate,
  getActivity,
  getActivityType,
  getProductInput,
  leaderboardEntryByChallengeAndPoints,
  listActivityTypes,
  listChallenges,
  listProductInputs,
  productByEmail,
  productByCategory,
  transactionBySentFrom,
  transactionBySentTo,
  getProfile,
} from '../../graphql/queries';

import { getChallengeWithActivityTypes, getChallengeWithLeaderboard } from '../../graphql/customQueries';

import {
  ChallengeByActiveAndStartDateQuery,
  GetActivityTypeQuery,
  GetChallengeQuery,
  GetProductInputQuery,
  ListChallengesQuery,
} from '../../API';

async function withUser<T>(callback: () => Promise<T>) {
  try {
    return await callback();
  } catch (err) {
    console.log(err);
    if (err === 'No current user' || err === 'Cannot retrieve a new session. Please authenticate.') {
      // go to sign in if we don't have the user
      Auth.federatedSignIn({ customProvider: 'Accenture' });
    }
    return null;
  }
}

export async function fetchActivity(props) {
  const activityData: any = await API.graphql(graphqlOperation(getActivity, { id: props }));
  return activityData.data.getActivity;
}

export async function fetchActivityType(props) {
  const activityTypeData: any = await API.graphql(graphqlOperation(getActivityType, { id: props }));
  const data = activityTypeData.data as GetActivityTypeQuery;

  return data.getActivityType;
}

export const fetchChallengeWithLeaderboard = (id: String) =>
  withUser(async () => {
    const challengeData: any = await API.graphql(graphqlOperation(getChallengeWithLeaderboard, { id }));
    return challengeData.data as GetChallengeQuery;
  });

export const fetchChallengeLeaderboardEntries = (props) =>
  withUser(async () => {
    const leaderboardData: any = await API.graphql(
      graphqlOperation(leaderboardEntryByChallengeAndPoints, {
        filter: props?.filter,
        limit: props?.limit,
        nextToken: props?.nextToken,
        challengeID: props?.challengeID,
        sortDirection: props?.sortDirection,
        totalPoints: props?.totalPoints,
      }),
    );
    return leaderboardData.data;
  });

export const fetchChallengeWithActivityTypes = (id: String) =>
  withUser(async () => {
    const challengeData: any = await API.graphql(graphqlOperation(getChallengeWithActivityTypes, { id }));
    return challengeData.data as GetChallengeQuery;
  });

export const fetchChallengesList = (props?) =>
  withUser(async () => {
    const challengeListData: any = await API.graphql(
      graphqlOperation(listChallenges, { filter: props?.filter, limit: props?.limit, nextToken: props?.nextToken }),
    );
    const data = challengeListData.data as ListChallengesQuery;
    return data.listChallenges;
  });

export async function fetchActivitiesList(props) {
  const activityListData: any = await API.graphql(
    graphqlOperation(activityByVisibilityAndCreatedAt, {
      filter: props.filter,
      limit: props.limit,
      nextToken: props.nextToken,
      visibility: props.visibility,
      sortDirection: props.sortDirection,
      createdAt: props?.createdAt,
    }),
  );

  return activityListData.data.activityByVisibilityAndCreatedAt;
}

export const fetchActivitiesListByDate = (props?) =>
  withUser(async () => {
    const activityListData: any = await API.graphql(
      graphqlOperation(activityByEmailAndActivityDate, {
        filter: props?.filter,
        limit: props?.limit,
        nextToken: props?.nextToken,
        email: props?.email,
        sortDirection: props?.sortDirection,
        activityDate: props?.activityDate,
      }),
    );

    return activityListData.data.activityByEmailAndActivityDate;
  });

export const fetchActiveChallengesListByDate = (props?) =>
  withUser(async () => {
    const challengeListData: any = await API.graphql(
      graphqlOperation(challengeByActiveAndStartDate, {
        filter: props?.filter,
        limit: props?.limit,
        nextToken: props?.nextToken,
        startDate: props?.startDate,
        sortDirection: props?.sortDirection,
        active: props?.active,
      }),
    );
    const data = challengeListData.data as ChallengeByActiveAndStartDateQuery;
    return data.challengeByActiveAndStartDate;
  });

export async function fetchActivitiesTypesList(props) {
  const activityTypesListData: any = await API.graphql(
    graphqlOperation(listActivityTypes, { filter: props.filter, limit: props.limit, nextToken: props.nextToken }),
  );

  return activityTypesListData.data.listActivityTypes;
}

export async function addActivity(props) {
  await API.graphql(graphqlOperation(createActivity, { input: props }));
}

export async function addActivityType(props) {
  const activity: any = await API.graphql(graphqlOperation(createActivityType, { input: props }));
  return activity.data.createActivityType;
}

export async function updateMyActivityType(props) {
  const resp: any = await API.graphql(graphqlOperation(updateActivityType, { input: props }));
  return resp.data.updateActivityType;
}

export async function deleteMyActivity(props) {
  const resp: any = await API.graphql(graphqlOperation(deleteActivityType, { input: props }));
  return resp.data.deleteActivityType;
}

export async function updateMyChallenge(props) {
  const resp: any = await API.graphql(graphqlOperation(updateChallenge, { input: props }));
  return resp.data.updateChallenge;
}

export async function deleteMyChallenge(props) {
  const resp: any = await API.graphql(graphqlOperation(deleteChallenge, { input: props }));
  return resp.data.deleteChallenge.id;
}

export async function addChallenge(props) {
  const resp: any = await API.graphql(graphqlOperation(createChallenge, { input: props }));
  return resp.data.createChallenge.id;
}

export async function uploadImage(key: string, file: File) {
  return Storage.put(key, file, {
    level: 'public',
    contentType: file.type,
  });
}

export async function getUploadedImageUrl(key: string) {
  return Storage.get(key, {
    level: 'public',
    download: false,
  });
}

export async function getUploadedImagesUrls(keys: string[]) {
  return Promise.all(
    keys.map((keyAct) => {
      return getUploadedImageUrl(keyAct);
    }),
  );
}

export async function getProducts() {
  const activityTypeData: any = await API.graphql(graphqlOperation(listProductInputs));
  return activityTypeData.data.listProductInputs;
}

export async function getProduct(props) {
  const Product: any = await API.graphql(graphqlOperation(getProductInput, { id: props }));
  const data = Product.data.getProductInput as GetProductInputQuery;
  return data;
}

export async function addProduct(props) {
  await API.graphql(graphqlOperation(createProductInput, { input: props }));
}

export async function getMyProducts(props) {
  const categoryProducts: any = await API.graphql(graphqlOperation(productByEmail, { email: props }));
  return categoryProducts.data.productByEmail;
}

export async function getCategoryProducts(props) {
  const categoryProducts: any = await API.graphql(graphqlOperation(productByCategory, { category: props }));
  return categoryProducts.data.productByCategory;
}

export async function updateReserveProduct(props) {
  const resp: any = await API.graphql(graphqlOperation(updateProductInput, { input: props }));
  return resp.data.updateProductInput;
}

export async function getUserProfileByEmail(props) {
  const resp: any = await API.graphql(graphqlOperation(getProfile, { id: props }));
  return resp.data.getProfile;
}

export async function createNewProfile(props) {
  const resp: any = await API.graphql(graphqlOperation(createProfile, { input: props }));
  return resp.data.createProfile;
}

export async function createMyTransaction(props) {
  const resp: any = await API.graphql(graphqlOperation(createTransaction, { input: props }));
  return resp.data.createTransaction;
}

export async function listMyTransactionsTo(props) {
  const resp: any = await API.graphql(graphqlOperation(transactionBySentTo, { sentToEmail: props }));
  return resp.data.transactionBySentTo.items;
}

export async function listMyTransactionsFrom(props) {
  const resp: any = await API.graphql(graphqlOperation(transactionBySentFrom, { sentFromEmail: props }));
  return resp.data.transactionBySentFrom.items;
}

export async function updateProfileInput(props) {
  const resp: any = await API.graphql(graphqlOperation(updateProfile, { input: props }));
  return resp.data.updateProfile;
}

export async function deleteProduct(props) {
  const resp: any = await API.graphql(graphqlOperation(deleteProductInput, { input: props }));
  return resp.data.deleteProductInput;
}
