import React from 'react';
import { Grid, createStyles, makeStyles, Theme, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Logo from '../../icons/GoGreenLogo.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: '10px',
      marginTop: '1rem',
      textAlign: 'center',
    },
    fullWidth: {
      width: '80%',
      margin: '0 10%',
      marginTop: '1em',
    },
    button: {
      color: 'white',
      float: 'right',
      marginRight: '10%',
      backgroundColor: '#7500C0',
      borderRadius: '1em',
      fontSize: '1.75em',
      background: 'linear-gradient(146deg, rgba(0,255,119,1) 10%, rgba(117,0,192,1) 45%)',
      '&:hover': {
        backgroundColor: '#34e218',
        boxShadow: '0px 0px 15px 5px #7500C0',
      },
      marginBottom: '5%',
    },
    buttonMargin: {
      margin: '5px',
      width: '80%',
      [theme.breakpoints.down('xs')]: {
        margin: '0',
      },
    },
    gridCenter: {
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
    },
  }),
);

export function EcoSwapHomePage() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.container}>
      <Grid container spacing={8} className={classes.fullWidth}>
        <Grid item xs={12} className={classes.fullWidth}>
          <img src={Logo} className={classes.fullWidth} alt="Accenture" />
        </Grid>
        <Grid justifyContent="center" className={classes.fullWidth}>
          &nbsp;
        </Grid>
        <Grid justifyContent="center" className={classes.fullWidth}>
          <h2 style={{ fontStyle: 'oblique' }}>I would like to...</h2>
        </Grid>
        <Grid item xs={12} lg={6} className={classes.gridCenter}>
          <Button
            color="default"
            variant="contained"
            className={`${classes.button}  ${classes.buttonMargin}`}
            onClick={() => history.push('/ecoswap/addproduct')}
          >
            Add a Product
          </Button>
        </Grid>
        <Grid item xs={12} lg={6} className={classes.gridCenter}>
          <Button
            color="default"
            variant="contained"
            className={`${classes.button}  ${classes.buttonMargin}`}
            onClick={() => history.push('/ecoswap/category')}
          >
            Get a Product
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
