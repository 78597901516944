import React from 'react';
import { createStyles, makeStyles, Theme, Grid } from '@material-ui/core';
import { CategoryBox } from '../../components/categories/CategoryBox';
import { Categories } from '../../components/products/ProductCategories';
import Image from '../../icons/backgrounds/BG2.jpg';
import { SearchHeader } from '../../components/headers/SearchHeader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      width: '100%',
      height: '100%',
      backgroundImage: `url(${Image})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    categoryWrapper: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      alignItems: 'center',
      flexDirection: 'column',
    },
    subheader: {
      width: '70vw',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
      [theme.breakpoints.down('md')]: {
        margin: '10px 0px !important',
      },
    },
    text: {
      fontSize: '25px',
      color: '#7500C0',
    },
    line: {
      width: '76%',
      height: '5px',
      marginLeft: '1em',
      backgroundColor: '#7500C0',
      background: 'linear-gradient(200deg, rgba(0,255,119,1) 10%, rgba(117,0,192,1) 33%)',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    categoryBox: {
      width: '70vw',
      height: '55vh !important',
      background: 'white',
      borderRadius: '20px',
      WebkitBoxShadow: '7px 5px 15px 5px rgba(0,0,0,0.47);',
      boxShadow: '7px 5px 15px 5px rgba(0,0,0,0.47);',
      textAlign: 'center',
      display: 'grid',
      gridTemplateColumns: 'repeat(4,1fr)',
      gridTemplateRows: 'repeat(2,1fr)',
      gridColumnGap: '0px',
      gridRowGap: '0px',
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: 'repeat(2,1fr)',
        gridTemplateRows: 'repeat(4,1fr)',
      },
    },
  }),
);

export const CategoryPage = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.background}>
        <SearchHeader hasAddProductButton />
        <div className={classes.categoryWrapper}>
          <Grid container className={classes.subheader}>
            <Grid item className={classes.text}>
              <b>Select</b> a category
            </Grid>
            <Grid item className={classes.line} />
          </Grid>
          <div className={classes.categoryBox}>
            {Categories.map((category) => (
              <CategoryBox category={category} key={category} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
