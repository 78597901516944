/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createActivity = /* GraphQL */ `
  mutation CreateActivity(
    $input: CreateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    createActivity(input: $input, condition: $condition) {
      id
      activityTypeId
      email
      userName
      amount
      description
      points
      activityDate
      visibility
      imageKey
      imageKeys
      createdAt
      updatedAt
      owner
      activityType {
        id
        challengeID
        name
        pointsPerAmount
        description
        editors
        url
        urlDescription
        amountDescription
        unit
        order
        createdAt
        updatedAt
        owner
        challenge {
          id
          name
          active
          editors
          draft
          startDate
          endDate
          description
          createdAt
          updatedAt
          owner
        }
      }
    }
  }
`;
export const updateActivity = /* GraphQL */ `
  mutation UpdateActivity(
    $input: UpdateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    updateActivity(input: $input, condition: $condition) {
      id
      activityTypeId
      email
      userName
      amount
      description
      points
      activityDate
      visibility
      imageKey
      imageKeys
      createdAt
      updatedAt
      owner
      activityType {
        id
        challengeID
        name
        pointsPerAmount
        description
        editors
        url
        urlDescription
        amountDescription
        unit
        order
        createdAt
        updatedAt
        owner
        challenge {
          id
          name
          active
          editors
          draft
          startDate
          endDate
          description
          createdAt
          updatedAt
          owner
        }
      }
    }
  }
`;
export const deleteActivity = /* GraphQL */ `
  mutation DeleteActivity(
    $input: DeleteActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    deleteActivity(input: $input, condition: $condition) {
      id
      activityTypeId
      email
      userName
      amount
      description
      points
      activityDate
      visibility
      imageKey
      imageKeys
      createdAt
      updatedAt
      owner
      activityType {
        id
        challengeID
        name
        pointsPerAmount
        description
        editors
        url
        urlDescription
        amountDescription
        unit
        order
        createdAt
        updatedAt
        owner
        challenge {
          id
          name
          active
          editors
          draft
          startDate
          endDate
          description
          createdAt
          updatedAt
          owner
        }
      }
    }
  }
`;
export const createActivityType = /* GraphQL */ `
  mutation CreateActivityType(
    $input: CreateActivityTypeInput!
    $condition: ModelActivityTypeConditionInput
  ) {
    createActivityType(input: $input, condition: $condition) {
      id
      challengeID
      name
      pointsPerAmount
      description
      editors
      url
      urlDescription
      amountDescription
      unit
      order
      createdAt
      updatedAt
      owner
      challenge {
        id
        name
        active
        editors
        draft
        startDate
        endDate
        description
        createdAt
        updatedAt
        activityTypes {
          nextToken
        }
        owner
        leaderboard {
          nextToken
        }
      }
    }
  }
`;
export const updateActivityType = /* GraphQL */ `
  mutation UpdateActivityType(
    $input: UpdateActivityTypeInput!
    $condition: ModelActivityTypeConditionInput
  ) {
    updateActivityType(input: $input, condition: $condition) {
      id
      challengeID
      name
      pointsPerAmount
      description
      editors
      url
      urlDescription
      amountDescription
      unit
      order
      createdAt
      updatedAt
      owner
      challenge {
        id
        name
        active
        editors
        draft
        startDate
        endDate
        description
        createdAt
        updatedAt
        activityTypes {
          nextToken
        }
        owner
        leaderboard {
          nextToken
        }
      }
    }
  }
`;
export const deleteActivityType = /* GraphQL */ `
  mutation DeleteActivityType(
    $input: DeleteActivityTypeInput!
    $condition: ModelActivityTypeConditionInput
  ) {
    deleteActivityType(input: $input, condition: $condition) {
      id
      challengeID
      name
      pointsPerAmount
      description
      editors
      url
      urlDescription
      amountDescription
      unit
      order
      createdAt
      updatedAt
      owner
      challenge {
        id
        name
        active
        editors
        draft
        startDate
        endDate
        description
        createdAt
        updatedAt
        activityTypes {
          nextToken
        }
        owner
        leaderboard {
          nextToken
        }
      }
    }
  }
`;
export const createChallenge = /* GraphQL */ `
  mutation CreateChallenge(
    $input: CreateChallengeInput!
    $condition: ModelChallengeConditionInput
  ) {
    createChallenge(input: $input, condition: $condition) {
      id
      name
      active
      editors
      draft
      startDate
      endDate
      description
      createdAt
      updatedAt
      activityTypes {
        items {
          id
          challengeID
          name
          pointsPerAmount
          description
          editors
          url
          urlDescription
          amountDescription
          unit
          order
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
      leaderboard {
        items {
          id
          name
          email
          owner
          challengeID
          totalPoints
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateChallenge = /* GraphQL */ `
  mutation UpdateChallenge(
    $input: UpdateChallengeInput!
    $condition: ModelChallengeConditionInput
  ) {
    updateChallenge(input: $input, condition: $condition) {
      id
      name
      active
      editors
      draft
      startDate
      endDate
      description
      createdAt
      updatedAt
      activityTypes {
        items {
          id
          challengeID
          name
          pointsPerAmount
          description
          editors
          url
          urlDescription
          amountDescription
          unit
          order
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
      leaderboard {
        items {
          id
          name
          email
          owner
          challengeID
          totalPoints
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteChallenge = /* GraphQL */ `
  mutation DeleteChallenge(
    $input: DeleteChallengeInput!
    $condition: ModelChallengeConditionInput
  ) {
    deleteChallenge(input: $input, condition: $condition) {
      id
      name
      active
      editors
      draft
      startDate
      endDate
      description
      createdAt
      updatedAt
      activityTypes {
        items {
          id
          challengeID
          name
          pointsPerAmount
          description
          editors
          url
          urlDescription
          amountDescription
          unit
          order
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
      leaderboard {
        items {
          id
          name
          email
          owner
          challengeID
          totalPoints
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createLeaderboardEntry = /* GraphQL */ `
  mutation CreateLeaderboardEntry(
    $input: CreateLeaderboardEntryInput!
    $condition: ModelLeaderboardEntryConditionInput
  ) {
    createLeaderboardEntry(input: $input, condition: $condition) {
      id
      name
      email
      owner
      challengeID
      totalPoints
      createdAt
      updatedAt
      challenge {
        id
        name
        active
        editors
        draft
        startDate
        endDate
        description
        createdAt
        updatedAt
        activityTypes {
          nextToken
        }
        owner
        leaderboard {
          nextToken
        }
      }
    }
  }
`;
export const updateLeaderboardEntry = /* GraphQL */ `
  mutation UpdateLeaderboardEntry(
    $input: UpdateLeaderboardEntryInput!
    $condition: ModelLeaderboardEntryConditionInput
  ) {
    updateLeaderboardEntry(input: $input, condition: $condition) {
      id
      name
      email
      owner
      challengeID
      totalPoints
      createdAt
      updatedAt
      challenge {
        id
        name
        active
        editors
        draft
        startDate
        endDate
        description
        createdAt
        updatedAt
        activityTypes {
          nextToken
        }
        owner
        leaderboard {
          nextToken
        }
      }
    }
  }
`;
export const deleteLeaderboardEntry = /* GraphQL */ `
  mutation DeleteLeaderboardEntry(
    $input: DeleteLeaderboardEntryInput!
    $condition: ModelLeaderboardEntryConditionInput
  ) {
    deleteLeaderboardEntry(input: $input, condition: $condition) {
      id
      name
      email
      owner
      challengeID
      totalPoints
      createdAt
      updatedAt
      challenge {
        id
        name
        active
        editors
        draft
        startDate
        endDate
        description
        createdAt
        updatedAt
        activityTypes {
          nextToken
        }
        owner
        leaderboard {
          nextToken
        }
      }
    }
  }
`;
export const createProductInput = /* GraphQL */ `
  mutation CreateProductInput(
    $input: CreateProductInputInput!
    $condition: ModelProductInputConditionInput
  ) {
    createProductInput(input: $input, condition: $condition) {
      id
      title
      email
      category
      price
      quality
      imageKey
      imageKeys
      description
      date
      size
      gender
      material
      reservedFor
      subcategory
      sold
      isInOffer
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateProductInput = /* GraphQL */ `
  mutation UpdateProductInput(
    $input: UpdateProductInputInput!
    $condition: ModelProductInputConditionInput
  ) {
    updateProductInput(input: $input, condition: $condition) {
      id
      title
      email
      category
      price
      quality
      imageKey
      imageKeys
      description
      date
      size
      gender
      material
      reservedFor
      subcategory
      sold
      isInOffer
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteProductInput = /* GraphQL */ `
  mutation DeleteProductInput(
    $input: DeleteProductInputInput!
    $condition: ModelProductInputConditionInput
  ) {
    deleteProductInput(input: $input, condition: $condition) {
      id
      title
      email
      category
      price
      quality
      imageKey
      imageKeys
      description
      date
      size
      gender
      material
      reservedFor
      subcategory
      sold
      isInOffer
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      email
      balance
      balanceOnHold
      createdAt
      updatedAt
      products {
        items {
          id
          title
          email
          category
          price
          quality
          imageKey
          imageKeys
          description
          date
          size
          gender
          material
          reservedFor
          subcategory
          sold
          isInOffer
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
      transactionsFrom {
        items {
          id
          sentFromEmail
          sentToEmail
          howMuch
          whatFor
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      transactionsTo {
        items {
          id
          sentFromEmail
          sentToEmail
          howMuch
          whatFor
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      email
      balance
      balanceOnHold
      createdAt
      updatedAt
      products {
        items {
          id
          title
          email
          category
          price
          quality
          imageKey
          imageKeys
          description
          date
          size
          gender
          material
          reservedFor
          subcategory
          sold
          isInOffer
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
      transactionsFrom {
        items {
          id
          sentFromEmail
          sentToEmail
          howMuch
          whatFor
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      transactionsTo {
        items {
          id
          sentFromEmail
          sentToEmail
          howMuch
          whatFor
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      email
      balance
      balanceOnHold
      createdAt
      updatedAt
      products {
        items {
          id
          title
          email
          category
          price
          quality
          imageKey
          imageKeys
          description
          date
          size
          gender
          material
          reservedFor
          subcategory
          sold
          isInOffer
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
      transactionsFrom {
        items {
          id
          sentFromEmail
          sentToEmail
          howMuch
          whatFor
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      transactionsTo {
        items {
          id
          sentFromEmail
          sentToEmail
          howMuch
          whatFor
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      sentFromEmail
      sentToEmail
      howMuch
      whatFor
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      sentFromEmail
      sentToEmail
      howMuch
      whatFor
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      sentFromEmail
      sentToEmail
      howMuch
      whatFor
      createdAt
      updatedAt
      owner
    }
  }
`;
