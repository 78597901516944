import React from 'react';
import ReactLoading from 'react-loading';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
    },
    loader: {
      width: '10em !important',
      height: '10em !important',
      padding: '12px',
      fill: '#7500C0 !important',
    },
  }),
);

export const LoadingBox = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <ReactLoading type="bubbles" color="black" className={classes.loader} />
    </div>
  );
};
