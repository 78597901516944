import { BottomNavigationAction, CssBaseline, Theme } from '@material-ui/core';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import IconPack from '../../../utils/IconPack';
import { EcoSwapNavLinks } from './EcoSwapNavLinks';
import { StyledBottomNavigation } from '../StyledBottomNavigation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: '0.875rem !important',
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.6rem !important',
      },
    },
  }),
);

const EcoSwapNavigation = () => {
  const classes = useStyles();
  return (
    <CssBaseline>
      <StyledBottomNavigation>
        <BottomNavigationAction
          label={<span className={classes.label}>Home</span>}
          classes={{ selected: 'SelectedBottom' }}
          icon={<IconPack.HomeIcon />}
          component={Link}
          to={EcoSwapNavLinks[0]}
        />
        <BottomNavigationAction
          label={<span className={classes.label}>Search</span>}
          classes={{ selected: 'SelectedBottom' }}
          icon={<SearchIcon />}
          component={Link}
          to={EcoSwapNavLinks[1]}
        />
        <BottomNavigationAction
          label={<span className={classes.label}>Add</span>}
          className="SelectedBottom"
          icon={<AddCircleOutlineIcon />}
          component={Link}
          to={EcoSwapNavLinks[2]}
        />
        <BottomNavigationAction
          label={<span className={classes.label}>Profile</span>}
          className="SelectedBottom"
          icon={<AccountCircleSharpIcon />}
          component={Link}
          to={EcoSwapNavLinks[3]}
        />
      </StyledBottomNavigation>
    </CssBaseline>
  );
};

export default withRouter(EcoSwapNavigation);
