class FetchLeaderboardParams {
  constructor(
    public challengeID: string | undefined,
    public sortDirection: 'ASC' | 'DESC',
    public limit: number,
    public nextToken: any,
    public totalPoints?: number,
    public filter?: any,
  ) {}
}

export default FetchLeaderboardParams;
