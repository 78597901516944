import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { CognitoUser } from '@aws-amplify/auth';
import { Auth, Hub } from 'aws-amplify';

interface User extends CognitoUser {}

interface Props {
  user?: User;
}

const defaults: Props = {};

const Context = createContext(defaults);

export const useAuthContext = () => useContext(Context);

interface ProviderProps {
  children: ReactNode;
}

const getUser = () =>
  Auth.currentAuthenticatedUser()
    .then((userData) => userData)
    .catch((err) => {
      console.log('Not signed in', err);
      // if we don't have user stored locally, reauthenticate
      Auth.federatedSignIn({ customProvider: 'Accenture' });
    });

const Provider = ({ children }: ProviderProps) => {
  const [user, setUser] = useState<User>();

  const contextValue: Props = {
    user,
  };

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          console.log('Sign in');
          getUser().then((userData) => setUser(userData));
          break;
        case 'signOut':
          setUser(undefined);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
        default:
          console.log(event);
      }
    });

    getUser().then((userData) => {
      console.log('Setting user');
      setUser(userData);
    });
  }, []);

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export const AuthContextProvider = Provider;
