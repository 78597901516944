import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Categories } from '../products/ProductCategories';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      listStyle: 'none',
      padding: '0',
      margin: '0',
      fontWeight: 'bold',
      paddingRight: '20px',
      position: 'absolute',
      borderRight: '5px solid #7500C0',
      [theme.breakpoints.down('md')]: {
        paddingLeft: '20px',
        paddingTop: '20px',
        fontSize: 'x-large',
        borderRight: 'none',
      },
    },
    navigationLink: {
      color: '#7500C0',
      '&:hover': {
        textDecoration: 'none',
        color: '#7500C0',
      },
      [theme.breakpoints.down('md')]: {
        color: '#7500C0',
      },
    },
    category: {
      marginBottom: '0.3em',
    },
    icon: {
      alignSelf: 'center',
      width: '2em',
      height: '2em',
      marginRight: '5px',
    },
  }),
);

interface Props {
  currentCategory: string;
}

export const CategoryNavigation = ({ currentCategory }: Props) => {
  const classes = useStyles();
  return (
    <ul className={classes.list}>
      {Categories.map((category) => (
        <a
          key={category}
          href={`/ecoswap/products?category=${category.toLowerCase()}`}
          className={classes.navigationLink}
          style={category.toLowerCase() === currentCategory ? { fontWeight: 'bold' } : { fontWeight: 'normal' }}
        >
          <li className={classes.category}>
            <img
              alt="category"
              className={classes.icon}
              src={`${process.env.PUBLIC_URL}/assets/categoryIcons/${category.toLowerCase()}.png`}
            />
            {category}
          </li>
        </a>
      ))}
    </ul>
  );
};
