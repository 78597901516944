import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    productInfoLabel: {
      color: '#7500C0',
      fontSize: '22px',
      fontWeight: 'bold',
    },
    productInfoText: {
      color: 'black',
      fontWeight: 'normal',
    },
  }),
);

export const ProductInfo = ({
  product: { description, category, subcategory, quality, price, size, gender, material, isInOffer },
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.productInfoLabel}>
        <p>
          Description: <span className={classes.productInfoText}>{description}</span>
        </p>
        <p>
          Category: <span className={classes.productInfoText}>{category}</span>
        </p>
        <p>
          Subcategory: <span className={classes.productInfoText}>{subcategory}</span>
        </p>
        {category === 'Clothes' && (
          <>
            <p>
              Size: <span className={classes.productInfoText}>{size}</span>
            </p>
            <p>
              Gender: <span className={classes.productInfoText}>{gender}</span>
            </p>
            <p>
              Material: <span className={classes.productInfoText}>{material}</span>
            </p>
          </>
        )}
        <p>
          Quality: <span className={classes.productInfoText}>{quality}</span>
        </p>
        <p>
          Price:{' '}
          <span className={classes.productInfoText}>
            {price} {price === 1 ? 'Point' : 'Points'}
          </span>
        </p>
        <p>
          Is In Offer: <span className={classes.productInfoText}>{isInOffer === null ? 'No' : 'Yes'}</span>
        </p>
      </div>
    </>
  );
};
