import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';

const useStyles = makeStyles({
  dialog: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export const ImageDialog = ({ onClose, open, children }) => {
  const classes = useStyles();

  return (
    <Dialog fullWidth maxWidth="lg" onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <div className={classes.dialog}>{children}</div>
    </Dialog>
  );
};
