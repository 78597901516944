import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      padding: '6px',
      border: '3px solid #7500C0',
      borderRadius: ' 2em',
      overflow: 'hidden',
      fontSize: '1rem',
      display: 'flex',
      flexFlow: 'row nowrap',
      width: '80%',
      background: 'white',
      '&:focus-within': {
        boxShadow: '0px 0px 15px 5px #7500C0',
      },
      [theme.breakpoints.down('md')]: {
        width: '55%',
        fontSize: '2rem',
      },
      [theme.breakpoints.down('sm')]: {
        width: '75%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '95%',
        fontSize: '1rem',
      },
    },
    input: {
      backgroundColor: '#fff !important',
      border: 'none',
      outline: 'none',
      width: '85%',
    },
    button: {
      backgroundColor: '#fff',
      border: 'none',
      outline: 'none',
      width: '15%',
      fontSize: '1.5rem',
      color: 'gray',
      display: 'grid',
      placeContent: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '25%',
        fontSize: '1.5rem',
      },
      [theme.breakpoints.down('md')]: {
        width: '25%',
        fontSize: '2rem',
      },
    },
  }),
);

export const SearchBar = () => {
  const classes = useStyles();
  const history = useHistory();
  const [search, setSearch] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (search) {
      history.push(`/ecoswap/products?search=${search}`);
    }
  };

  const handleSearchChange = ({ target }) => {
    setSearch(target.value);
  };

  return (
    <form onSubmit={handleSubmit} className={classes.search}>
      <input
        id="search"
        type="text"
        placeholder="Search for products..."
        onChange={handleSearchChange}
        className={classes.input}
      />
      <button className={classes.button} type="submit">
        <IconButton color="primary" aria-label="upload picture" component="span">
          <SearchIcon />
        </IconButton>
      </button>
    </form>
  );
};
