import React from 'react';
import { createStyles, makeStyles, Theme, Button, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { SearchBar } from '../SearchBar/SearchBar';
import { EcoswapHeader } from './EcoswapHeader';
import { HamburgerMenu } from '../HamburgerMenu/HamburgerMenu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    button: {
      color: 'white',
      float: 'right',
      marginRight: '2.5vw',
      backgroundColor: '#7500C0',
      borderRadius: '1em',
      fontSize: '1.75em',
      background: 'linear-gradient(146deg, rgba(0,255,119,1) 10%, rgba(117,0,192,1) 45%)',
      '&:hover': {
        backgroundColor: '#34e218',
        boxShadow: '0px 0px 15px 5px #7500C0',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: '1.5em',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '1em',
      },
    },
    searchBar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      order: 2,
      [theme.breakpoints.down('md')]: {
        order: 3,
      },
    },
    buttonWrapper: {
      order: 3,
      [theme.breakpoints.down('md')]: {
        order: 2,
      },
    },
  }),
);

type Props = {
  hasAddProductButton: boolean;
};

export const SearchHeader = ({ hasAddProductButton }: Props) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container className={classes.header}>
      <Grid item lg={4} md={6} xs={6} style={{ paddingLeft: '2.5vw' }}>
        <EcoswapHeader />
      </Grid>
      <Grid item className={classes.searchBar} lg={4} md={12} xs={12}>
        <SearchBar />
      </Grid>
      {hasAddProductButton ? (
        <Grid item lg={4} md={6} xs={6} className={classes.buttonWrapper}>
          <Button
            color="default"
            variant="contained"
            className={classes.button}
            onClick={() => history.push('/ecoswap/addproduct')}
          >
            Add Product
          </Button>
        </Grid>
      ) : (
        <HamburgerMenu />
      )}
    </Grid>
  );
};
