import React from 'react';
import { createStyles, Grid, makeStyles, SvgIcon, Theme } from '@material-ui/core';
import Logo from '../../icons/GoGreenLogo.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center',
    },
    icon: {
      height: 60,
      width: 60,
    },
    title: {
      justifyContent: 'center',
      color: '#7500c0',
      width: '100%',
      display: 'flex',
      padding: '5px 0px',
    },
    logo: {
      position: 'absolute',
      top: '30px',
      left: '30px',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  }),
);

export const GoGreenHeader = ({ icon, title }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.grid} container>
      <img src={Logo} alt="Logo" style={{ width: '13em' }} className={classes.logo} />
      <SvgIcon component={icon} className={classes.icon} />
      <h2 className={classes.title}>{title}</h2>
    </Grid>
  );
};
