const BookSubCategories = ['Kids', 'Teenage', 'Fiction', 'Textbook', 'Magazine', 'Others'];
const ClothesSubCategories = [
  'Pants',
  'T-shirt',
  'Jacket',
  'Pullover',
  'Hoodie',
  'Skirt',
  'Dress',
  'Suit',
  'Accessories',
  'Shoes',
  'Shirts',
];
const ElectronicsSubCategories = ['Phone', 'PC', 'TV', 'Video', 'Audio', 'Camera', 'White technology', 'Others'];
const GardenSubCategories = ['Tools', 'Mowers', 'Brushcutters', 'Furniture', 'Decorations', 'Others'];
const HobbiesSubCategories = ['Music', 'Games', 'Art', 'Sport', 'Languages', 'Others'];
const HouseHoldSubCategories = ['Art', 'Furniture', 'Decorations', 'Kitchen ', 'Others'];
const KidsSubCategories = ['Toys', 'Pushchair', 'Crib', 'Clothes', 'Others'];
const SportSubCategories = ['Ball sports', 'Winter sports', 'Martial arts', 'Summer sports', 'Fitness & Gym', 'Others'];

export const getSubcategories = (category: string) => {
  return {
    Books: BookSubCategories,
    Clothes: ClothesSubCategories,
    Electronics: ElectronicsSubCategories,
    Garden: GardenSubCategories,
    Hobbies: HobbiesSubCategories,
    Household: HouseHoldSubCategories,
    Kids: KidsSubCategories,
    Sport: SportSubCategories,
  }[category];
};

export const getCategoryMaxPrice = (category: string) => {
  return {
    Books: 10,
    Clothes: 10,
    Electronics: 200,
    Garden: 200,
    Hobbies: 100,
    Household: 200,
    Kids: 100,
    Sport: 100,
  }[category];
};

export const getClothesSubcategoryMaxPrice = (subcategory: string) => {
  return {
    Pants: 10,
    'T-shirt': 5,
    Jacket: 20,
    Pullover: 10,
    Hoodie: 10,
    Skirt: 10,
    Dress: 15,
    Suit: 15,
    Accessories: 100,
    Shoes: 25,
    Shirts: 15,
  }[subcategory];
};
