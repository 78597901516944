import React, { ChangeEvent } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      display: 'none',
    },
    margin: {
      marginLeft: '20px',
    },
    labelClass: {
      display: 'grid',
      flexDirection: 'column',
      justifyContent: 'end',
      fontWeight: 'bold',
      marginTop: '30px',
    },
    icon: {
      width: '2em',
      height: '2em',
    },
  }),
);

interface Props {
  uploadFileHandler: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function SelectPhoto({ uploadFileHandler }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.margin}>
      <label htmlFor="icon-button-file" className={classes.labelClass}>
        Upload a photo
        <IconButton color="primary" aria-label="upload picture" component="span">
          <PhotoCamera className={classes.icon} />
        </IconButton>
        <input
          accept="image/*"
          className={classes.input}
          id="icon-button-file"
          type="file"
          value=""
          multiple
          onChange={uploadFileHandler}
        />
      </label>
    </div>
  );
}
