import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, DialogActions, Dialog } from '@material-ui/core';

const useStyles = makeStyles({
  dialog: {
    margin: '30px 10px',
    textAlign: 'center',
    fontFamily: 'Graphik',
  },
  title: {
    textAlign: 'center',
  },
  succesButton: {
    marginRight: '10px',
    color: 'white',
    backgroundColor: 'green',
    '&:hover': {
      backgroundColor: 'green',
    },
  },
  errorButton: {
    marginRight: '10px',
    color: 'white',
    backgroundColor: 'red',
    '&:hover': {
      backgroundColor: 'red',
    },
  },
});

export default function InformationDialog({ onClose, open, title, children }) {
  const classes = useStyles();

  return (
    <Dialog fullWidth maxWidth="md" onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <div className={classes.dialog}>
        <h3>{title.toUpperCase()}</h3>
        <br />
        <h4>{children}</h4>
        <DialogActions>
          <Button
            size="large"
            onClick={onClose}
            className={title === 'Success' ? classes.succesButton : classes.errorButton}
          >
            OK
          </Button>
        </DialogActions>
        <br />
      </div>
    </Dialog>
  );
}

InformationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
