import React from 'react';
import { Fab, Theme } from '@material-ui/core';
import { createStyles, makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    FloatingButton: {
      marginRight: '5px',
      position: 'absolute',
      bottom: 0,
      right: 0,
      borderRadius: 8,
      backgroundColor: '#7500C0',
      maxWidth: '45px',
      maxHeight: '45px',
      minWidth: '45px',
      minHeight: '45px',
      '&:hover': {
        color: '#64FF50',
      },
      [theme.breakpoints.up('md')]: {
        right: '5%',
      },
    },
    AddIcon: {
      '&:hover': {
        color: '#64FF50',
      },
    },
  }),
);

const theme = createTheme({
  palette: {
    primary: {
      main: '#7500C0',
    },
  },
});

interface AddActivityButtonProps {
  challengeId: string | undefined;
}

const AddActivityButton = ({ challengeId }: AddActivityButtonProps) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Fab
        color="primary"
        aria-label="add"
        variant="extended"
        className={classes.FloatingButton}
        component={Link}
        to={`/gogreen/challenges/${challengeId}/activityTypes`}
      >
        <AddIcon className={classes.AddIcon} />
      </Fab>
    </ThemeProvider>
  );
};

export default AddActivityButton;
