import DIYIcon from '../icons/challangeTypesIcons/DIY.png';
import SecondHandIcon from '../icons/challangeTypesIcons/secondHand.png';
import SeparateIcon from '../icons/challangeTypesIcons/separate.png';
import CookIcon from '../icons/challangeTypesIcons/cook.png';
import DonateIcon from '../icons/challangeTypesIcons/donate.png';
import InitiativeIcon from '../icons/challangeTypesIcons/initiative.png';
import PackageFreeIcon from '../icons/challangeTypesIcons/packaging.png';
import FixingIcon from '../icons/challangeTypesIcons/fixing.png';
import BikeIcon from '../icons/challangeTypesIcons/bike.png';
import RunIcon from '../icons/challangeTypesIcons/run.png';
import CarIcon from '../icons/challangeTypesIcons/electricCar.png';
import TransportIcon from '../icons/challangeTypesIcons/transport.png';
import StairsIcon from '../icons/challangeTypesIcons/stairs.png';
import CountUsIcon from '../icons/challangeTypesIcons/borrow.png';

import MobilityChallange from '../icons/challanges/mobilityChallenge.png';
import EcoStarsChallenge from '../icons/challanges/ecostarsChallenge.png';

export const IconMap = [
  { title: 'Cooked my own meal', iconPath: CookIcon },
  { title: 'Separated waste correctly', iconPath: SeparateIcon },
  { title: 'Made a DIY gift', iconPath: DIYIcon },
  { title: 'Buy second-hand', iconPath: SecondHandIcon },
  { title: 'Donation', iconPath: DonateIcon },
  { title: 'Take initiative', iconPath: InitiativeIcon },
  { title: 'Shopped package free/local', iconPath: PackageFreeIcon },
  { title: 'Fixed something instead of buying new', iconPath: FixingIcon },
  { title: 'Walking', iconPath: RunIcon },
  { title: 'Biking', iconPath: BikeIcon },
  { title: 'Electric/Hybrid car', iconPath: CarIcon },
  { title: 'Public transport', iconPath: TransportIcon },
  { title: 'Stairs', iconPath: StairsIcon },
  { title: 'Count Us In program', iconPath: CountUsIcon },

  { title: 'Eco Stars Challenge', iconPath: EcoStarsChallenge },
  { title: 'Eco Household Challenge', iconPath: CookIcon },
  { title: 'Mobility challenge 2022', iconPath: MobilityChallange },
];

export const findIcon = (inputTitle: string) => {
  return (
    IconMap.find((icon) => icon.title.toLowerCase().includes(inputTitle.toLowerCase()))?.iconPath || MobilityChallange
  );
};
