import React, { useState } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  TextField,
  MenuItem,
  ListItemIcon,
  Checkbox,
  Box,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ClothesForm } from '../../components/forms/ClothesForm';
import InformationDialog from '../../components/dialogs/InformationDialog';
import { selectImageForUpload, UploadFile } from '../../components/products/AddProductHelpers';
import { addProduct, uploadImage } from '../../common/api/apiclient';
import { CreateProductInputInput } from '../../API';
import { useAuthContext } from '../../common/context/AuthContext';
import { Categories } from '../../components/products/ProductCategories';
import AddPhotos from '../../components/photos/AddPhotos';
import {
  getSubcategories,
  getClothesSubcategoryMaxPrice,
  getCategoryMaxPrice,
} from '../../components/categories/Subcategories';
import { EcoswapHeader } from '../../components/headers/EcoswapHeader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      marginLeft: '2em',
    },
    root: {
      flexGrow: 1,
      width: '100%',
    },
    fullWidth: {
      width: '80%',
      margin: '0 10%',
      marginTop: '10px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    title: {
      color: '#7500C0',
      padding: '5px 10%',
      fontSize: '30px',
    },
    icon: {
      alignSelf: 'center',
      width: '3em',
      height: '3em',
      [theme.breakpoints.down('xs')]: {
        width: '2rem',
        height: '2rem',
      },
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        padding: '0 10%',
      },
    },
  }),
);

const TypeOfQualities = ['New', 'Barely used', 'Used (not damaged)', 'Worn out'];

export const AddProductPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useAuthContext();
  const tokenPayload = user?.getSignInUserSession()?.getIdToken()?.payload;

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState<string>('');
  const [quality, setQuality] = useState<string>('');
  const [size, setSize] = useState<string>('');
  const [gender, setGender] = useState<string>('');
  const [material, setMaterial] = useState<string>('');
  const [price, setPrice] = useState<number>(1);
  const [maxRange, setMaxRange] = useState(10);
  const [uploadFiles, setUploadFiles] = useState<UploadFile[] | null>([]);
  const [currentSubcategories, setCurrentSubcategories] = useState<string[]>([]);
  const [infoAboutImage, setInfoAboutImage] = useState('');
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openImagesDialog, setOpenImagesDialog] = useState(false);

  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [checked, setChecked] = React.useState(true);

  const handleTitleChange = ({ target }) => {
    setTitle(target.value);
  };

  const handleDescriptionChange = ({ target }) => {
    setDescription(target.value);
  };

  const handleCategoryChange = ({ target }) => {
    setCategory(target.value);
    setCurrentSubcategories(getSubcategories(target.value)!);
    setMaxRange(getCategoryMaxPrice(target.value)!);
  };

  const handleSubcategoryChange = ({ target }) => {
    if (category === 'Clothes') {
      setMaxRange(getClothesSubcategoryMaxPrice(target.value)!);
    }
    setSubcategory(target.value);
  };

  const handleQualityChange = ({ target }) => {
    setQuality(target.value);
  };

  const handlePriceChange = ({ target }) => {
    setPrice(+target.value > 0 ? target.value : null);
  };

  const handleSuccessDialogClose = () => {
    setOpenSuccessDialog(false);
    history.push('/ecoswap/category');
  };

  const handleErrorDialogClose = () => {
    setOpenErrorDialog(false);
  };

  const handleImagesDialogClose = () => {
    setOpenImagesDialog(false);
  };
  console.log(uploadFiles);
  async function sumbitHandler(event) {
    event.preventDefault();
    uploadFiles?.map(async (file) => {
      await uploadImage(file.key, file.file!);
    });
    if (uploadFiles && uploadFiles?.length > 0) {
      const inputProduct: CreateProductInputInput = {
        title,
        description,
        category,
        subcategory,
        email: tokenPayload?.email,
        price,
        imageKeys: uploadFiles?.map((item) => item.key),
        date: null,
        material: material || null,
        reservedFor: null,
        sold: false,
        quality: quality || null,
        size: size || null,
        gender: gender || null,
        isInOffer: checked || null,
      };

      try {
        await addProduct(inputProduct).then(() => {
          setOpenSuccessDialog(true);
        });
      } catch (error) {
        setInfoAboutImage('There was an error when adding the product. Please try again.');
        setOpenErrorDialog(true);
      }
    } else {
      setInfoAboutImage('You have to upload atleast one image for this product.');
      setOpenErrorDialog(true);
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  console.log(uploadFiles);
  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <EcoswapHeader />
      </header>
      <h4 className={classes.title}>Add product</h4>
      <form onSubmit={sumbitHandler} className={classes.form}>
        <TextField
          id="title"
          label="Product name"
          value={title}
          onChange={handleTitleChange}
          className={classes.fullWidth}
          required
        />
        <TextField
          id="description"
          label="Product description"
          value={description}
          onChange={handleDescriptionChange}
          className={classes.fullWidth}
          multiline
          rows={4}
          required
        />
        <TextField
          id="category"
          label="Category"
          value={category}
          onChange={handleCategoryChange}
          select
          className={classes.fullWidth}
          required
        >
          {Categories.map((_category) => (
            <MenuItem key={_category} value={_category}>
              <ListItemIcon>
                <img
                  alt="category"
                  className={classes.icon}
                  src={`${process.env.PUBLIC_URL}/assets/categoryIcons/${_category.toLowerCase()}.png`}
                />
              </ListItemIcon>
              {_category}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="subcategory"
          label="Subcategory"
          value={subcategory}
          onChange={handleSubcategoryChange}
          select
          required
          className={classes.fullWidth}
        >
          {currentSubcategories.map((subcategory_name) => (
            <MenuItem key={subcategory_name} value={subcategory_name}>
              {subcategory_name}
            </MenuItem>
          ))}
        </TextField>
        {category === 'Clothes' && (
          <ClothesForm
            subCategory={subcategory}
            size={size}
            setSize={setSize}
            gender={gender}
            setGender={setGender}
            material={material}
            setMaterial={setMaterial}
          />
        )}
        <TextField
          id="quality"
          label="Condition"
          value={quality}
          onChange={handleQualityChange}
          select
          className={classes.fullWidth}
          required
        >
          {TypeOfQualities.map((quality_name) => (
            <MenuItem key={quality_name} value={quality_name}>
              {quality_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="price"
          label={`Price (Maximum is ${maxRange} points)`}
          type="number"
          value={price}
          onChange={handlePriceChange}
          className={classes.fullWidth}
          InputProps={{ inputProps: { min: 1, max: maxRange } }}
          required
        />
        <h6 className={classes.fullWidth}> </h6>
        <Box className={classes.fullWidth}>
          <Typography variant="caption" display="block">
            Is in Offer
          </Typography>
          <Checkbox checked={checked} onChange={handleChange} />
        </Box>
        <h6 className={classes.fullWidth}>
          <b>* - Required field</b>
        </h6>
        <AddPhotos
          uploadFileHandler={(e) =>
            selectImageForUpload(e, setInfoAboutImage, uploadFiles, setUploadFiles, setOpenImagesDialog)
          }
          uploadFiles={uploadFiles}
          setUploadFiles={setUploadFiles}
          isProduct
          isUpdate={false}
        />
      </form>
      <InformationDialog open={openSuccessDialog} onClose={handleSuccessDialogClose} title="Success">
        Product was successfully added
      </InformationDialog>
      <InformationDialog open={openImagesDialog} onClose={handleImagesDialogClose} title="Success">
        {infoAboutImage}
      </InformationDialog>
      <InformationDialog open={openErrorDialog} onClose={handleErrorDialogClose} title="Error">
        {infoAboutImage}
      </InformationDialog>
    </div>
  );
};
