import { useState } from 'react';

const initialFormValues = {
  name: '',
  email: '',
  message: '',
};

export const useFormControls = () => {
  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({} as any);

  const validate: any = (fieldValues = values) => {
    const temp: any = { ...errors };
    if ('name' in fieldValues) temp.name = fieldValues.name ? '' : 'This field is required.';
    if ('email' in fieldValues) {
      temp.email = fieldValues.email ? '' : 'This field is required.';
      if (fieldValues.email)
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email) ? '' : 'Email is not valid.';
    }
    if ('message' in fieldValues) temp.message = fieldValues.message.length !== 0 ? '' : 'This field is required.';

    setErrors({
      ...temp,
    });
  };

  const handleInputValue = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const formIsValid = (fieldValues = values) => {
    const isValid =
      fieldValues.name && fieldValues.email && fieldValues.message && Object.values(errors).every((x) => x === '');

    return isValid;
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    // const isValid = Object.values(errors).every((x) => x === '') && formIsValid();
  };

  return {
    values,
    errors,
    handleInputValue,
    handleFormSubmit,
    formIsValid,
  };
};
